import _ from "lodash";

import * as React from "react";

import { LessonType, SubjectType } from "@/types";

import { AuthContext } from "../AuthProvider";
import { classManager, firebaseRequest, toastNotifier } from "../../utils";
import { ToastType } from "../../enums";
import {
  Button,
  Form,
  Icon,
  Input,
  List,
  Message,
  Modal,
} from "semantic-ui-react";
import { Colors } from "../../constants";
import ConfirmationModal from "./ConfirmationModal";
import { forceOnlyNumbers } from "../../utils/inputControllers";

interface Props {
  creating?: boolean;
  subject?: SubjectType;
  trigger: React.ReactNode;
  onClose: Function;
  open: boolean;
  onDelete?: Function;
}

const SubjectModal = (props: Props) => {
  const {
    creating,
    subject: subjectProps,
    trigger,
    onClose,
    open,
    onDelete,
  } = props;

  const { currentUser, lessons } = React.useContext(AuthContext);

  const [subject, setSubject] = React.useState(null);

  const [loading, setLoading] = React.useState<boolean>(false);

  const [errors, setErrors] = React.useState<{ [field: string]: string }>({
    title: null,
  });

  React.useEffect(() => {
    if (!creating) {
      setSubject(subjectProps);
    } else {
      setSubject({
        student_id: currentUser?.uid,
        title: "",
        semester: 1,
      });
    }
  }, [subjectProps, creating, currentUser?.uid]);

  const handleChange = React.useCallback((e, { name, value }) => {
    setSubject((prev) => ({
      ...prev,
      [name]: name === "semester" ? _.parseInt(value) : value,
    }));
  }, []);

  const handleDeleteSubject = React.useCallback(() => {
    // Deleting associated lessons and questions
    _.map(lessons, (lesson: LessonType) => {
      if (lesson.subject_id === subjectProps?.id) {
        firebaseRequest.deleteWhere("questions", "lesson_id", lesson?.id);
      }
    });

    firebaseRequest.deleteWhere("lessons", "subject_id", subjectProps?.id);

    firebaseRequest.delete("subjects", subjectProps?.id);

    if (_.isFunction(onDelete)) onDelete();

    if (_.isFunction(onClose)) onClose();

    toastNotifier(
      ToastType.error,
      `Vous venez de supprimer la matière : ${subjectProps?.title}`
    );
  }, [lessons, onClose, onDelete, subjectProps?.id, subjectProps?.title]);

  const handleResetInformation = React.useCallback(() => {
    setSubject({
      student_id: currentUser?.uid,
      title: creating ? "" : subjectProps?.title,
      semester: creating ? 1 : subjectProps?.semester,
    });
  }, [creating, currentUser?.uid, subjectProps?.semester, subjectProps?.title]);

  const handleClose = React.useCallback(() => {
    if (_.isFunction(onClose)) onClose();

    handleResetInformation();

    setErrors({
      title: null,
    });
  }, [handleResetInformation, onClose]);

  const handleSave = React.useCallback(() => {
    setLoading(true);

    if (_.isEmpty(subject?.title)) {
      setErrors({
        title: "Le titre de la matière ne doit pas être vide.",
      });

      classManager.addTemporaryClass("title", "horizontalShaking");
    } else {
      if (creating) {
        firebaseRequest.post("subjects", {
          title: subject?.title,
          semester: _.isNaN(subject?.semester) ? 1 : subject?.semester,
          student_id: currentUser?.uid,
        });
      } else {
        _.map(lessons, (lesson: LessonType) => {
          if (lesson?.subject_id === subjectProps?.id) {
            firebaseRequest.update("lessons", lesson?.id, {
              semester: _.isNaN(subject?.semester) ? 1 : subject?.semester,
            });
          }
        });

        firebaseRequest.update("subjects", subject?.id, {
          title: subject?.title,
          semester: _.isNaN(subject?.semester) ? 1 : subject?.semester,
          student_id: currentUser?.uid,
        });
      }

      handleClose();
      handleResetInformation();

      toastNotifier(
        ToastType.success,
        `Vous avez ${creating ? "ajouté" : "modifié"} la matière : ${
          subject?.title
        }`
      );
    }

    setLoading(false);
  }, [
    creating,
    currentUser?.uid,
    handleClose,
    handleResetInformation,
    lessons,
    subject,
    subjectProps?.id,
  ]);

  return (
    <Modal
      closeIcon
      closeOnDimmerClick={false}
      closeOnEscape={false}
      open={open}
      trigger={trigger}
      onClose={handleClose}
      size="small"
      className="fadeSlideInFromTop"
    >
      <Modal.Header>
        <div style={{ width: "100%", textAlign: "center" }}>
          <Icon
            name="folder"
            size="large"
            style={{
              position: "absolute",
              left: 20,
              color: Colors.sageGreenDark,
            }}
          />
          &nbsp;
          <span
            style={{
              fontFamily: "websiteTitle",
              fontSize: "1.3em",
              color: Colors.sageGreenDark,
            }}
          >
            {creating ? "Nouvelle matière" : subject?.title}
          </span>
        </div>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description style={{ width: "100%" }}>
          <Form>
            <Form.Group>
              <Form.Field width={10} error={errors?.title}>
                <label>Titre</label>
                <Input
                  id="title"
                  name="title"
                  icon="pencil"
                  placeholder="Cardiologie"
                  type="text"
                  onChange={handleChange}
                  value={subject?.title}
                />
              </Form.Field>

              <Form.Field width={1} />

              <Form.Field width={4}>
                <label>Semestre</label>
                <Input
                  min={0}
                  icon="calendar alternate outline"
                  type="number"
                  name="semester"
                  value={subject?.semester}
                  placeholder="1, 2, 3..."
                  onChange={handleChange}
                  onKeyPress={forceOnlyNumbers}
                />
              </Form.Field>

              {!creating && (
                <Form.Field
                  width={3}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <ConfirmationModal
                    onConfirm={handleDeleteSubject}
                    message={
                      <div>
                        <p>
                          Vous êtes sur le point de supprimer la matière :{" "}
                          <span
                            style={{
                              fontStyle: "italic",
                              fontWeight: "bold",
                              fontFamily: "websiteTitle",
                              fontSize: "1.2em",
                              paddingLeft: "1em",
                            }}
                          >
                            {subject?.title}
                          </span>
                        </p>
                        <i>
                          Cela entraînera la suppression de tous les cours liés
                          et toutes les questions associées à ces cours.
                        </i>
                      </div>
                    }
                    title={
                      <div style={{ width: "100%", textAlign: "center" }}>
                        <Icon
                          name="trash alternate"
                          size="large"
                          style={{
                            position: "absolute",
                            left: 20,
                            color: Colors.danger,
                          }}
                        />
                        &nbsp;
                        <span
                          style={{
                            fontFamily: "websiteTitle",
                            fontSize: "1.3em",
                            color: Colors.danger,
                          }}
                        >
                          Confirmation de la suppression
                        </span>
                      </div>
                    }
                    trigger={
                      <span className="zoomAnimation2">
                        <Icon
                          link
                          size="big"
                          name="trash alternate"
                          style={{ color: Colors.danger }}
                        />
                      </span>
                    }
                  />
                </Form.Field>
              )}
            </Form.Group>

            {errors.title && (
              <Form.Field>
                <Message negative className="fadeSlideIn">
                  <Message.Header>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Icon name="close" /> Erreur lors de l'enregistrement du
                      cours
                    </div>
                  </Message.Header>
                  <List>
                    <List.Item
                      icon="pencil"
                      content="Le titre de la matière ne doit pas être vide."
                      className="fadeSlideIn"
                    />
                  </List>
                </Message>
              </Form.Field>
            )}
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button basic loading={loading} onClick={handleClose}>
          <Icon name="cancel" />
          {creating ? "Annuler" : "Fermer"}
        </Button>
        <Button
          loading={loading}
          onClick={handleSave}
          disabled={_.isEqual(subjectProps, subject)}
          style={{
            backgroundColor: Colors.positive,
            color: Colors.ghostWhite,
          }}
        >
          <Icon name="checkmark" />
          Sauvegarder
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

SubjectModal.defaultProps = {
  creating: false,
  subject: null,
  onDelete: null,
};

export default SubjectModal;
