import _ from "lodash";

/**
 * Returns true is there are no errors
 *
 * @param errors Dictionnary containing errors
 */
const checkIfNoErrors = (errors: { [field: string]: string }) => {
  let check: boolean = true;

  if (_.isEmpty(errors)) {
    check = true;
  } else {
    _.map(_.entries(errors), ([field, message]) => {
      if (message) {
        check = false;
      }
    });
  }

  return check;
};

export default checkIfNoErrors;
