import _ from "lodash";

import * as React from "react";

import { Grid, Icon, Input, Label } from "semantic-ui-react";
import { cardio } from "ldrs";

import { ToastType } from "../enums";
import { Colors } from "../constants";
import { DeadlineType } from "@/types";
import {
  checkIfNoErrors,
  checkUpdateDeadlines,
  classManager,
  firebaseRequest,
  toastNotifier,
} from "../utils";

import { forceOnlyNumbers } from "../utils/inputControllers";

import { AuthContext } from "../components/AuthProvider";

import learningImage from "../assets/Learning-bro.png";
import deadlineImage from "../assets/Deadline-bro.png";
import dizzyFaceImage from "../assets/Dizzy-face-bro.png";
import relaxingImage from "../assets/Relaxing-home-bro.png";

const Deadlines = () => {
  const { deadlines } = React.useContext(AuthContext);

  cardio.register();

  const [newDeadlines, setNewDeadlines] = React.useState<DeadlineType>(null);
  const [errors, setErrors] = React.useState<{ [field: string]: string }>({
    lower: null,
    upper: null,
    logic: null,
  });

  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!_.isEmpty(deadlines)) {
      setNewDeadlines(deadlines[0]);
    }
  }, [deadlines]);

  const handleDeadlinesReset = React.useCallback(() => {
    setNewDeadlines(deadlines[0]);

    setErrors({
      lower: null,
      upper: null,
      logic: null,
    });

    toastNotifier(
      ToastType.warn,
      `Vous avez annulé les modifications sur vos deadlines`
    );
  }, [deadlines]);

  const handleDeadlinesChange = React.useCallback(
    (e: React.BaseSyntheticEvent, { name, value }) => {
      if (e.target.value.length < 3) {
        setNewDeadlines((prev: DeadlineType) => ({
          ...prev,
          [name]: value ? _.parseInt(value) : "",
        }));
      }
    },
    []
  );

  const handleDeadlinesSave = React.useCallback(() => {
    setLoading(true);

    let currentErrors = checkUpdateDeadlines(newDeadlines);
    setErrors(currentErrors);

    if (
      _.isNil(currentErrors.lower) &&
      _.isNil(currentErrors.upper) &&
      _.isNil(currentErrors.logic)
    ) {
      firebaseRequest.update("deadlines", newDeadlines?.id, {
        lower: newDeadlines?.lower,
        upper: newDeadlines?.upper,
      });

      toastNotifier(
        ToastType.success,
        `Nouvelles deadlines : ${newDeadlines?.lower} - ${newDeadlines?.upper} jours`
      );
    } else {
      if (currentErrors.lower) {
        classManager.addTemporaryClass("lower", "horizontalShaking");
      }

      if (currentErrors.upper) {
        classManager.addTemporaryClass("upper", "horizontalShaking");
      }

      if (currentErrors.logic) {
        classManager.addTemporaryClass("lower", "horizontalShaking");
        classManager.addTemporaryClass("upper", "horizontalShaking");
      }

      _.map(_.entries(currentErrors), ([field, message], index) => {
        if (message) {
          toastNotifier(ToastType.error, `${message}`);
        }
      });
    }

    setTimeout(() => {
      setLoading(false);
    }, 1200);
  }, [newDeadlines]);

  return (
    <div className="background centeredChildren">
      <Grid
        stackable
        className="centeredChildren"
        style={{
          width: "95%",
          height: "95%",
          maxHeight: "95%",
        }}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            marginTop: "2.5%",
          }}
          className="centeredChildren"
        >
          <div style={{ width: "35%", height: "65%" }}>
            <div className="deadlineCircle fadeIn">
              <div className="deadlineItem">
                <img
                  alt="dizzy face man emoji"
                  src={dizzyFaceImage}
                  className="dropShadow fadeSlideIn"
                  style={{
                    objectFit: "contain",
                    width: "15%",
                  }}
                />
                <img
                  alt="girl laying in bed"
                  src={relaxingImage}
                  className="dropShadow fadeSlideInFromRight"
                  style={{
                    objectFit: "contain",
                    width: "17%",
                  }}
                />
              </div>
              <div className="deadlineItem">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt="boy learning"
                    src={learningImage}
                    className="dropShadow fadeSlideInFromRight"
                    style={{
                      objectFit: "contain",
                      width: "15%",
                      marginLeft: "42%",
                    }}
                  />

                  <div
                    className="transparentBackground fadeSlideInFromRight"
                    style={{
                      padding: "1.2em",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "1.1em",
                      height: "50%",
                      width: "18%",
                      marginLeft: 15,
                    }}
                  >
                    <Label
                      content="1"
                      circular
                      style={{
                        position: "absolute",
                        top: 5,
                        right: 5,
                        color: Colors.ghostWhite,
                        backgroundColor: Colors.warn,
                      }}
                    />
                    <p>
                      Votre première deadline ! <br />
                      Fini le repos, commencez à réviser
                      <br />à partir de :
                    </p>
                    <div style={{ display: "inline" }}>
                      <Input
                        name="lower"
                        id="lower"
                        min="0"
                        max="99"
                        type="number"
                        placeholder="5"
                        value={newDeadlines?.lower}
                        onChange={handleDeadlinesChange}
                        onKeyPress={forceOnlyNumbers}
                        error={
                          !_.isNil(errors?.lower) || !_.isNil(errors?.logic)
                        }
                        style={{ width: "5em" }}
                      />{" "}
                      &nbsp;jours
                    </div>
                  </div>
                </div>
              </div>
              <div className="deadlineItem">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: "27%",
                  }}
                >
                  <div
                    className="transparentBackground fadeSlideIn"
                    style={{
                      padding: "1.2em",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "1.1em",
                      height: "50%",
                      width: "22%",
                      marginRight: 15,
                    }}
                  >
                    <Label
                      content="2"
                      circular
                      style={{
                        position: "absolute",
                        top: 5,
                        right: 5,
                        color: Colors.ghostWhite,
                        backgroundColor: Colors.danger,
                      }}
                    />
                    <p>
                      Votre deuxième deadline ! <br />
                      Révisez absolument vos cours
                      <br />à partir de :
                    </p>
                    <div style={{ display: "inline" }}>
                      <Input
                        name="upper"
                        id="upper"
                        min="0"
                        max="99"
                        type="number"
                        placeholder="10"
                        value={newDeadlines?.upper}
                        onChange={handleDeadlinesChange}
                        onKeyPress={forceOnlyNumbers}
                        error={
                          !_.isNil(errors?.upper) || !_.isNil(errors?.logic)
                        }
                        style={{ width: "5em" }}
                      />{" "}
                      &nbsp;jours
                    </div>
                  </div>
                  <img
                    alt="running after time"
                    src={deadlineImage}
                    className="dropShadow fadeSlideIn"
                    style={{
                      objectFit: "contain",
                      width: "25%",
                    }}
                  />
                </div>
              </div>
              {/* Circle's center */}

              {!loading &&
                (newDeadlines?.lower !== deadlines?.[0]?.lower ||
                  newDeadlines?.upper !== deadlines?.[0]?.upper) && (
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <div
                      className="fadeSlideIn zoomAnimation2 centeredChildren dropShadow"
                      style={{
                        width: "100px",
                        height: "100px",
                        cursor: "pointer",
                        borderRadius: "50%",
                        backgroundColor: Colors.cream,
                      }}
                    >
                      <Icon
                        name="redo"
                        size="huge"
                        onClick={handleDeadlinesReset}
                        className="fadeSlideIn dropShadowInput"
                        style={{ color: Colors.danger }}
                      />
                    </div>

                    <div
                      className="fadeSlideInFromRight zoomAnimation2 centeredChildren dropShadow"
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "50%",
                        cursor: "pointer",
                        backgroundColor: Colors.cream,
                      }}
                    >
                      <Icon
                        name="check"
                        size="huge"
                        onClick={handleDeadlinesSave}
                        className="fadeSlideInFromRight dropShadowInput"
                        style={{ color: Colors.positive }}
                      />
                    </div>
                  </div>
                )}
              {loading && (
                <div
                  className="centeredChildren"
                  style={{
                    flexDirection: "column",
                    height: "100%",
                    marginTop: -50,
                  }}
                >
                  <l-cardio
                    size="150"
                    stroke={10}
                    speed="1.3"
                    color={checkIfNoErrors(errors) ? "white" : Colors.danger}
                  />
                  <p
                    style={{
                      fontFamily: "websiteTitle",
                      paddingTop: 10,
                      fontSize: "1.4em",
                      color: checkIfNoErrors(errors) ? "white" : Colors.danger,
                    }}
                  >
                    {checkIfNoErrors(errors)
                      ? "Sauvegarde de vos deadlines ..."
                      : "Veuillez corriger les erreurs !"}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default Deadlines;
