import _ from "lodash";

import * as React from "react";

import { cardio } from "ldrs";
import {
  Card,
  Dropdown,
  Grid,
  Header,
  Icon,
  IconGroup,
  Segment,
} from "semantic-ui-react";

import { AuthContext } from "../components/AuthProvider";

import { LessonType, SubjectType } from "@/types";
import { Colors } from "../constants";
import {
  LessonCard,
  LessonModal,
  SubjectModal,
  SubjectPopup,
} from "../components";

import questionImage from "../assets/Questions-bro.png";
import womanThinkingImage from "../assets/Woman-thinking-bro.png";
import noLessonsAddedImage from "../assets/college-project-bro.png";

const Lessons = () => {
  const { lessons, subjects } = React.useContext(AuthContext);

  cardio.register();

  const [loading, setLoading] = React.useState<boolean>(true);

  const [semesterOptions, setSemesterOptions] = React.useState([]);
  const [activeSemester, setActiveSemester] = React.useState<number>(1);

  const [activeSubject, setActiveSubject] = React.useState<string>(null);

  const [openNewSubjectModal, setOpenNewSubjectModal] =
    React.useState<boolean>(false);

  const [openNewLessonModal, setOpenNewLessonModal] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  });

  React.useEffect(() => {
    if (_.isArray(subjects) && !_.isEmpty(subjects)) {
      const minSemester = _.minBy(
        subjects,
        (subject) => subject.semester
      )?.semester;

      setActiveSemester(minSemester);

      if (subjects.length === 1) {
        setSemesterOptions([
          {
            key: `semester-${subjects[0]?.semester}`,
            text: `Semestre ${subjects[0]?.semester}`,
            value: subjects[0]?.semester,
          },
        ]);
      } else {
        setSemesterOptions(
          _.orderBy(
            _.map(_.uniq(_.map(subjects, "semester")), (semester) => ({
              key: `semester-${semester}`,
              text: `Semestre ${semester}`,
              value: semester,
            })),
            "value",
            "asc"
          )
        );
      }
    }
  }, [subjects]);

  const handleActiveSubjectChange = React.useCallback(
    (subjectId: string) => {
      if (activeSubject === subjectId) {
        setActiveSubject(null);
      } else {
        setActiveSubject(subjectId);
      }
    },
    [activeSubject]
  );

  const handleSemesterChange = React.useCallback(
    (e, { value }) => {
      setActiveSemester(value);
      handleActiveSubjectChange(null);
    },
    [handleActiveSubjectChange]
  );

  const semesterDropdownRender = React.useMemo(
    () => (
      <Dropdown
        fluid
        item
        button
        labeled
        floating
        scrolling
        icon="calendar alternate outline"
        className="icon fadeSlideIn"
        placeholder="Semestre"
        onChange={handleSemesterChange}
        options={semesterOptions}
        value={activeSemester}
        style={{
          color: Colors.sageGreenDarker,
          backgroundColor: Colors.ghostWhite,
          fontSize: "1.1em",
        }}
      />
    ),
    [activeSemester, semesterOptions, handleSemesterChange]
  );

  const renderLoader = React.useMemo(
    () => (
      <div
        className="centeredChildren background"
        style={{
          flexDirection: "column",
          height: "100%",
        }}
      >
        <l-cardio size="200" stroke={15} speed="1.3" color="white" />
        <p
          style={{
            fontFamily: "websiteTitle",
            paddingTop: 25,
            color: "white",
            fontSize: "2.2em",
          }}
        >
          Chargement de vos données ...
        </p>
      </div>
    ),
    []
  );

  const renderLessonCards = React.useMemo(() => {
    let subjectFilteredLessons: Array<LessonType> = _.orderBy(
      _.filter(
        lessons,
        (lesson: LessonType) => lesson.subject_id === activeSubject
      ),
      ["last_time_studied"],
      ["asc"]
    );

    let semesterFilteredLessons: Array<LessonType> = _.orderBy(
      _.filter(
        lessons,
        (lesson: LessonType) => lesson.semester === activeSemester
      ),
      ["last_time_studied"],
      ["asc"]
    );

    return (
      <Card.Group itemsPerRow={4} stackable>
        {activeSubject ? ( // Cours filtrés par matière
          _.isEmpty(subjectFilteredLessons) ? (
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Header
                as="h1"
                className="fadeSlideIn"
                style={{ fontFamily: "websiteTitle", marginTop: "5%" }}
              >
                Vous n'avez pas encore ajouté de cours pour cette matière !
              </Header>
              <img
                alt="woman thinking"
                src={noLessonsAddedImage}
                className="dropShadow fadeSlideIn"
                style={{
                  objectFit: "contain",
                  width: "50%",
                  height: "50%",
                }}
              />
            </div>
          ) : (
            _.map(subjectFilteredLessons, (lesson: LessonType) => (
              <LessonCard
                lesson={lesson}
                activeSemester={activeSemester}
                key={`lesson-${lesson?.id}`}
              />
            ))
          )
        ) : // Cours filtrés par semestre
        _.isEmpty(semesterFilteredLessons) ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Header
              as="h1"
              className="fadeSlideIn"
              style={{ fontFamily: "websiteTitle", marginTop: "5%" }}
            >
              Vous n'avez pas encore ajouté de cours pour ce semestre !
            </Header>
            <img
              alt="woman thinking"
              src={noLessonsAddedImage}
              className="dropShadow fadeSlideIn"
              style={{
                objectFit: "contain",
                width: "50%",
                height: "50%",
              }}
            />
          </div>
        ) : (
          _.map(semesterFilteredLessons, (lesson: LessonType) => (
            <LessonCard
              lesson={lesson}
              activeSemester={activeSemester}
              key={`lesson-${lesson?.id}`}
            />
          ))
        )}
      </Card.Group>
    );
  }, [activeSemester, activeSubject, lessons]);

  return (
    <div className="background">
      <Grid columns={3} style={{ margin: "1em", height: "100%" }}>
        <Grid.Column width={2}>
          {!_.isEmpty(subjects) && semesterDropdownRender}
          <SubjectModal
            creating
            open={openNewSubjectModal}
            onClose={() => setOpenNewSubjectModal(false)}
            trigger={
              <Segment
                raised
                textAlign="center"
                onClick={() => setOpenNewSubjectModal(true)}
                className="fadeSlideIn zoomAnimation2"
                style={{
                  cursor: "pointer",
                  fontFamily: "websiteTitle",
                  fontSize: "1.1em",
                  backgroundColor: Colors.cream,
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
              >
                <IconGroup>
                  <Icon name="folder" />
                  <Icon
                    corner="bottom right"
                    name="add"
                    style={{ color: Colors.positive }}
                  />
                </IconGroup>
                &nbsp;Nouvelle matière
              </Segment>
            }
          />

          {!_.isEmpty(subjects) && (
            <LessonModal
              creating
              activeSemester={activeSemester}
              open={openNewLessonModal}
              onClose={() => setOpenNewLessonModal(false)}
              trigger={
                <Segment
                  raised
                  textAlign="center"
                  onClick={() => setOpenNewLessonModal(true)}
                  className="fadeSlideIn zoomAnimation2"
                  style={{
                    cursor: "pointer",
                    fontFamily: "websiteTitle",
                    fontSize: "1.1em",
                    backgroundColor: Colors.creamLight,
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                >
                  <IconGroup>
                    <Icon name="book" />
                    <Icon
                      corner="bottom right"
                      name="add"
                      style={{ color: Colors.positive }}
                    />
                  </IconGroup>
                  &nbsp;Nouveau cours
                </Segment>
              }
            />
          )}
        </Grid.Column>
        <Grid.Column width={12} style={{ height: "97%", maxHeight: "97%" }}>
          <div
            style={{
              height: "100%",
              maxHeight: "100%",
              overflowY: "scroll",
              padding: "1.5em",
            }}
            className="transparentBackground fadeIn"
          >
            {_.isEmpty(subjects) && _.isEmpty(lessons) && (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Header
                  as="h1"
                  className="fadeSlideIn"
                  style={{ fontFamily: "websiteTitle" }}
                >
                  <Icon name="plus circle" style={{ color: Colors.cream }} />
                  Commencez par ajouter une matière !
                </Header>
                <img
                  alt="question"
                  src={questionImage}
                  className="dropShadow fadeSlideIn"
                  style={{
                    objectFit: "contain",
                    width: "80%",
                    height: "80%",
                  }}
                />
              </div>
            )}

            {!_.isEmpty(subjects) && _.isEmpty(lessons) && (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Header
                  as="h1"
                  className="fadeSlideIn"
                  style={{ fontFamily: "websiteTitle" }}
                >
                  <Icon
                    name="plus circle"
                    style={{ color: Colors.creamLight }}
                  />
                  Super, maintenant ajoutez un cours !
                </Header>
                <img
                  alt="woman thinking"
                  src={womanThinkingImage}
                  className="dropShadow fadeSlideIn"
                  style={{
                    objectFit: "contain",
                    width: "80%",
                    height: "80%",
                  }}
                />
              </div>
            )}
            {loading ? renderLoader : renderLessonCards}
          </div>
        </Grid.Column>
        <Grid.Column width={2} textAlign="center">
          <div style={{ height: "97%", maxHeight: "97%", overflowY: "scroll" }}>
            {_.map(
              _.orderBy(
                _.filter(
                  subjects,
                  (subject: SubjectType) => subject.semester === activeSemester
                ),
                ["title"],
                ["asc"]
              ),
              (subject: SubjectType) => (
                <SubjectPopup
                  key={`subject-${subject?.id}`}
                  subject={subject}
                  onDelete={() => setActiveSubject(null)}
                  trigger={
                    <Segment
                      raised
                      onClick={() => handleActiveSubjectChange(subject.id)}
                      className="fadeSlideInFromRight"
                      style={{
                        backgroundColor: Colors.cream,
                        cursor: "pointer",
                        fontSize: "1.1em",
                        fontFamily: "websiteTitle",
                        ...(activeSubject === subject?.id && {
                          fontWeight: "bold",
                          fontStyle: "italic",
                        }),
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          width: "100%",
                        }}
                      >
                        <div style={{ width: "10%" }}>
                          {activeSubject === subject.id && (
                            <Icon
                              name="hand point right"
                              size="big"
                              style={{
                                position: "absolute",
                                left: 0,
                                top: "25%",
                                color: Colors.sageGreenDark,
                              }}
                              className={`fadeSlideIn ${
                                activeSubject === subject?.id
                                  ? "selectedListItem"
                                  : ""
                              }`}
                            />
                          )}
                        </div>
                        <div
                          className={
                            activeSubject === subject?.id
                              ? "selectedListItem"
                              : ""
                          }
                          style={{ width: "80%" }}
                        >
                          {subject?.title}
                        </div>
                        <div
                          style={{
                            width: "10%",
                          }}
                        >
                          {_.size(
                            _.filter(
                              lessons,
                              (lesson: LessonType) =>
                                lesson?.subject_id === subject?.id
                            )
                          ) !== 0 && (
                            <span
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 7,
                                color: Colors.sageGreen,
                              }}
                            >
                              {_.size(
                                _.filter(
                                  lessons,
                                  (lesson: LessonType) =>
                                    lesson?.subject_id === subject?.id
                                )
                              )}
                            </span>
                          )}
                        </div>
                      </div>
                    </Segment>
                  }
                />
              )
            )}
          </div>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default Lessons;
