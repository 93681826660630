import * as React from "react";

import { Grid, Icon } from "semantic-ui-react";

import { Colors } from "../constants";

import { Link } from "react-router-dom";

import reliefStudent from "../assets/reliefStudent.png";
import dizzyFace from "../assets/Dizzy-face-bro.png";
import stressedStudent from "../assets/Student-stress-bro.png";
import questionsMan from "../assets/Questions-bro.png";

const Home = () => {
  const [step, setStep] = React.useState<number>(0);

  const handleStepChange = (direction: "previous" | "next") => {
    setStep((prev: number) => {
      if (direction === "previous" && prev > 0) {
        return prev - 1;
      }

      return prev + 1;
    });
  };

  let catchPhrase: string;
  let illustration: string;
  let description: React.ReactNode;

  switch (step) {
    case 0:
      illustration = stressedStudent;
      catchPhrase =
        "Vous êtes stressé(e) à l'idée de devoir planifier vos révisions ?";
      description = (
        <div key={`description-${step}`}>
          <p className="bubble thought dropShadow academyParagraph fadeSlideIn">
            C'est fou comme juste l'idée de devoir planifier mes révisions me
            met dans un état pas possible. J'ai l'impression que ça va me
            bouffer tout mon temps et mon énergie.
          </p>
          <p className="bubble thought dropShadow academyParagraph fadeSlideIn">
            Tu sais, je suis à deux doigts de faire une crise d'angoisse juste
            en pensant à devoir planifier mes révisions. Ça me met vraiment sous
            pression.
          </p>
        </div>
      );
      break;

    case 1:
      illustration = dizzyFace;
      catchPhrase = "Vous ne savez pas par où commencer vos révisions ?";
      description = (
        <div key={`description-${step}`}>
          <p className="bubble thought dropShadow academyParagraph fadeSlideIn">
            Je suis complètement paumé(e), j'ai aucune idée de par où attaquer
            mes révisions. C'est un vrai bordel dans ma tête ...
          </p>
          <p className="bubble thought dropShadow academyParagraph fadeSlideIn">
            Je suis devant mes cours et je me sens un peu perdu(e). Je sais pas
            par où commencer mes révisions. Tu penses qu'on devrait faire quoi
            en premier ?
          </p>
        </div>
      );
      break;

    case 2:
      illustration = questionsMan;
      catchPhrase = "Vous avez du mal à trouver votre méthode de révision ?";
      description = (
        <div key={`description-${step}`}>
          <p className="bubble thought dropShadow academyParagraph fadeSlideIn">
            Je me demande si la méthode des J serait vraiment efficace pour mes
            révisions, ou si ça va juste compliquer les choses ?
          </p>
          <p className="bubble thought dropShadow academyParagraph fadeSlideIn">
            La méthode Pomodoro, est-ce que ça pourrait être la solution à mes
            problèmes de procrastination en révisions, ou est-ce que ça va juste
            ajouter à mon stress ?
          </p>
          <p className="bubble thought dropShadow academyParagraph fadeSlideIn">
            Est-ce que les flashcards vont réellement m'aider à apprendre
            efficacement, ou est-ce que ça va juste me faire perdre du temps que
            je pourrais utiliser autrement ?
          </p>
        </div>
      );
      break;

    case 3:
      catchPhrase = "Alors vous êtes au bon endroit, rejoignez Academy !";
      illustration = reliefStudent;
      description = (
        <div key={`description-${step}`}>
          <p className="dropShadow academyParagraph fadeSlideIn">
            <Icon
              name="calendar alternate outline"
              style={{ color: Colors.creamLight }}
            />
            &nbsp;Un <u style={{ color: Colors.creamLight }}>planning</u>{" "}
            réalisé rien que pour vous et mis à jour à chacune de vos connexion
            !
          </p>
          <p className="dropShadow academyParagraph fadeSlideIn">
            <Icon name="smile outline" style={{ color: Colors.creamLight }} />
            &nbsp;Des indicateurs visuels vous guidant vers les{" "}
            <u style={{ color: Colors.creamLight }}>cours prioritaires</u> à
            réviser !
          </p>
          <p className="dropShadow academyParagraph fadeSlideIn">
            <Icon name="question circle" style={{ color: Colors.creamLight }} />
            &nbsp;Des&nbsp;
            <u style={{ color: Colors.creamLight }}>questions de révision</u>
            &nbsp; pour que vous puissiez retenir le plus important !
          </p>
          <p className="dropShadow academyParagraph fadeSlideIn">
            <Icon name="hourglass half" style={{ color: Colors.creamLight }} />
            &nbsp;Et encore plein de fonctionnalités à venir...
          </p>

          <div
            className="centeredChildren fadeSlideIn"
            style={{ marginTop: "10%" }}
          >
            <Link to="/register">
              <div
                className="dropShadow attentionSeeker "
                style={{
                  textAlign: "center",
                  backgroundColor: Colors.ghostWhite,
                  borderRadius: 30,
                  padding: 15,
                  width: 350,
                  cursor: "pointer",
                }}
              >
                <p
                  style={{
                    fontFamily: "websiteTitle",
                    fontSize: "2em",
                    color: Colors.sageGreenDarker,
                  }}
                >
                  <Icon name="hand point right outline" />
                  Rejoignez Academy
                </p>
              </div>
            </Link>
          </div>
        </div>
      );

      break;

    default:
      break;
  }

  return (
    <div className="background">
      <Grid stackable style={{ margin: "3em", height: "90%" }} stretched>
        {/* Catch phrase */}
        <Grid.Row>
          <h2
            className="academySubtitle fadeSlideIn"
            key={`catchphrase-${step}`}
          >
            {catchPhrase}
          </h2>
        </Grid.Row>
        <Grid.Row verticalAlign="middle">
          {step > 0 && (
            <Grid.Column
              width={2}
              textAlign="center"
              className="centeredChildren"
            >
              <Icon
                size="huge"
                name="chevron circle left"
                onClick={() => handleStepChange("previous")}
                className="fadeSlideIn dropShadow zoomAnimation"
                style={{ color: Colors.ghostWhite, cursor: "pointer" }}
              />
            </Grid.Column>
          )}

          {/* Illustration */}
          <Grid.Column width={step > 0 ? 6 : 8} className="centeredChildren">
            <img
              key={`illustration-${step}`}
              alt={`illustration-step-{1}`}
              src={illustration}
              className="dropShadow fadeSlideIn"
              style={{
                objectFit: "contain",
                width: step !== 0 ? "100%" : "80%",
              }}
            />
          </Grid.Column>
          {/* Description */}
          <Grid.Column width={step === 3 ? 8 : 6}>{description}</Grid.Column>
          {step < 3 && (
            <Grid.Column
              width={2}
              textAlign="center"
              className="centeredChildren"
            >
              <Icon
                size="huge"
                name="chevron circle right"
                onClick={() => handleStepChange("next")}
                className="fadeSlideInFromRight dropShadow zoomAnimation"
                style={{
                  color: Colors.ghostWhite,
                  cursor: "pointer",
                }}
              />
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default Home;
