import _ from "lodash";

import * as React from "react";
import { Link } from "react-router-dom";

import { LessonType, SubjectType } from "@/types";
import { Colors } from "../../constants";

import { AuthContext } from "../AuthProvider";

import dayjs from "dayjs";

import {
  Button,
  Form,
  Icon,
  Input,
  List,
  Message,
  Modal,
  TextArea,
} from "semantic-ui-react";

import { classManager, firebaseRequest, toastNotifier } from "../../utils";
import { ToastType } from "../../enums";
import ConfirmationModal from "./ConfirmationModal";
import { forceOnlyNumbers } from "../../utils/inputControllers";

interface Props {
  lesson?: LessonType;
  creating?: boolean;
  activeSemester: number;
  trigger: React.ReactNode;
  onClose: Function;
  open: boolean;
}

const LessonModal = (props: Props) => {
  const { currentUser, subjects } = React.useContext(AuthContext);

  const {
    lesson: lessonProps,
    creating,
    activeSemester,
    trigger,
    onClose,
    open,
  } = props;

  const [lesson, setLesson] = React.useState(null);

  const [loading, setLoading] = React.useState<boolean>(false);

  const [errors, setErrors] = React.useState<{ [field: string]: string }>({
    title: null,
  });

  React.useEffect(() => {
    // Si on n'est pas en création : on modifie un cours
    if (!creating) {
      setLesson(lessonProps);
    } else {
      setLesson({
        title: "",
        times_studied: 0,
        subject_id: subjects?.[0]?.id,
        student_id: currentUser?.uid,
        note: "",
        semester: activeSemester,
        last_time_studied: dayjs().format("YYYY-MM-DD"),
      });
    }
  }, [creating, lessonProps, subjects, currentUser?.uid, activeSemester]);

  const handleChange = React.useCallback(
    (e, { name, value }) => {
      if (name === "subject_id") {
        // On change également le semestre quand on change la matière
        setLesson((prev) => ({
          ...prev,
          subject_id: value,
          semester: _.find(
            subjects,
            (subject: SubjectType) => subject.id === value
          )?.semester,
        }));
      } else {
        setLesson((prev) => ({
          ...prev,
          [name]: name === "times_studied" ? _.parseInt(value) : value,
        }));
      }
    },
    [subjects]
  );

  const handleDeleteLesson = React.useCallback(() => {
    firebaseRequest.deleteWhere("questions", "lesson_id", lessonProps?.id);
    firebaseRequest.delete("lessons", lessonProps?.id);

    if (_.isFunction(onClose)) onClose();

    toastNotifier(
      ToastType.error,
      `Vous venez de supprimer le cours : ${lessonProps?.title}`
    );
  }, [lessonProps?.id, lessonProps?.title, onClose]);

  const handleResetInformation = React.useCallback(() => {
    setLesson({
      title: creating ? "" : lessonProps?.title,
      times_studied: creating ? 0 : lessonProps?.times_studied,
      subject_id: creating ? subjects?.[0]?.id : lessonProps?.subject_id,
      student_id: currentUser?.uid,
      note: creating ? "" : lessonProps?.note,
      semester: creating ? subjects?.[0]?.semester : lessonProps?.semester,
      last_time_studied: creating
        ? dayjs().format("YYYY-MM-DD")
        : lessonProps?.last_time_studied,
    });
  }, [creating, currentUser?.uid, lessonProps, subjects]);

  const handleClose = React.useCallback(() => {
    if (_.isFunction(onClose)) onClose();

    handleResetInformation();

    setErrors({
      title: null,
    });
  }, [handleResetInformation, onClose]);

  const handleSave = React.useCallback(() => {
    setLoading(true);

    if (_.isEmpty(lesson?.title)) {
      setErrors({
        title: "Le titre du cours ne doit pas être vide.",
      });

      classManager.addTemporaryClass("title", "horizontalShaking");
    } else {
      if (creating) {
        firebaseRequest.post("lessons", {
          title: lesson?.title,
          times_studied: _.isNaN(lesson?.times_studied)
            ? 0
            : lesson?.times_studied,
          subject_id: lesson?.subject_id,
          student_id: currentUser?.uid,
          note: lesson?.note,
          semester: lesson?.semester,
          last_time_studied: lesson?.last_time_studied,
        });
      } else {
        firebaseRequest.update("lessons", lesson?.id, {
          title: lesson?.title,
          times_studied: _.isNaN(lesson?.times_studied)
            ? 0
            : lesson?.times_studied,
          subject_id: lesson?.subject_id,
          note: lesson?.note,
          semester: lesson?.semester,
          last_time_studied: lesson?.last_time_studied,
        });
      }

      handleClose();
      handleResetInformation();

      toastNotifier(
        ToastType.success,
        `Vous avez ${creating ? "ajouté" : "modifié"} le cours : ${
          lesson?.title
        }`
      );
    }

    setLoading(false);
  }, [creating, handleResetInformation, handleClose, lesson, currentUser?.uid]);

  const subjectDropdownOptions = _.map(
    _.orderBy(subjects, ["semester", "title"], ["asc", "asc"]),
    (subject: SubjectType) => ({
      key: `key-subject-${subject?.id}-${subject?.title}`,
      text: `S${subject.semester} - ${subject?.title}`,
      value: subject?.id,
    })
  );

  return (
    <Modal
      closeIcon
      closeOnDimmerClick={false}
      closeOnEscape={false}
      open={open}
      trigger={trigger}
      onClose={handleClose}
      size="small"
      className="fadeSlideInFromTop"
    >
      {!creating && (
        <Link to={`/training/${lessonProps?.id}`}>
          <div
            className="attentionSeeker"
            style={{
              backgroundColor: Colors.cream,
              position: "absolute",
              top: "-25%",
              left: "40%",
              padding: "15px 20px",
              borderRadius: 50,
              fontFamily: "websiteTitle",
              color: Colors.sageGreenDark,
              fontSize: "1.5em",
              cursor: "pointer",
            }}
          >
            <Icon name="hand point right outline" />
            &nbsp;Réviser
          </div>
        </Link>
      )}

      <Modal.Header>
        <div style={{ width: "100%", textAlign: "center" }}>
          <Icon
            name="book"
            size="large"
            style={{
              position: "absolute",
              left: 20,
              color: Colors.sageGreenDark,
            }}
          />
          &nbsp;
          <span
            style={{
              fontFamily: "websiteTitle",
              fontSize: "1.3em",
              color: Colors.sageGreenDark,
            }}
          >
            {creating ? "Nouveau cours" : lesson?.title}
          </span>
        </div>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description style={{ width: "100%" }}>
          <Form>
            <Form.Group>
              <Form.Field width={9} error={errors?.title}>
                <label>Titre</label>
                <Input
                  id="title"
                  name="title"
                  icon="pencil"
                  placeholder="Étude des ECG"
                  type="text"
                  onChange={handleChange}
                  value={lesson?.title}
                />
              </Form.Field>
              <Form.Field width={1} />

              {!creating && (
                <Form.Field
                  width={6}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <ConfirmationModal
                    onConfirm={handleDeleteLesson}
                    message={
                      <div>
                        <p>
                          Vous êtes sur le point de supprimer le cours :{" "}
                          <span
                            style={{
                              fontStyle: "italic",
                              fontWeight: "bold",
                              fontFamily: "websiteTitle",
                              fontSize: "1.2em",
                              paddingLeft: "1em",
                            }}
                          >
                            {lesson?.title}
                          </span>
                        </p>
                        <i>
                          Cela entraînera la suppression de toutes les questions
                          associées à ce cours.
                        </i>
                      </div>
                    }
                    title={
                      <div style={{ width: "100%", textAlign: "center" }}>
                        <Icon
                          name="trash alternate"
                          size="large"
                          style={{
                            position: "absolute",
                            left: 20,
                            color: Colors.danger,
                          }}
                        />
                        &nbsp;
                        <span
                          style={{
                            fontFamily: "websiteTitle",
                            fontSize: "1.3em",
                            color: Colors.danger,
                          }}
                        >
                          Confirmation de la suppression
                        </span>
                      </div>
                    }
                    trigger={
                      <span className="zoomAnimation2">
                        <Icon
                          link
                          size="big"
                          name="trash alternate"
                          style={{ color: Colors.danger }}
                        />
                      </span>
                    }
                  />
                </Form.Field>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Field width={4}>
                <label>Dernière révision</label>
                <Input
                  id="last_time_studied"
                  name="last_time_studied"
                  type="date"
                  value={dayjs(lesson?.last_time_studied).format("YYYY-MM-DD")}
                  onChange={handleChange}
                />
              </Form.Field>

              <Form.Field width={1} />

              <Form.Field width={4}>
                <label>Nombre de révisions</label>
                <Input
                  min={0}
                  icon="eye"
                  type="number"
                  name="times_studied"
                  value={lesson?.times_studied}
                  placeholder="1, 2, 3..."
                  onChange={handleChange}
                  onKeyPress={forceOnlyNumbers}
                />
              </Form.Field>

              <Form.Field width={1} />

              <Form.Field width={6}>
                <label>Matière</label>
                <Form.Dropdown
                  search
                  selection
                  name="subject_id"
                  placeholder="Choisissez une matière"
                  noResultsMessage="Cette matière n'existe pas."
                  value={lesson?.subject_id}
                  onChange={handleChange}
                  options={subjectDropdownOptions}
                />
              </Form.Field>
            </Form.Group>
            <Form.Field width={16}>
              <label>Notes</label>
              <TextArea
                name="note"
                value={lesson?.note}
                onChange={handleChange}
                placeholder="Écrivez des informations utiles ici !"
              />
            </Form.Field>
            {errors.title && (
              <Form.Field>
                <Message negative className="fadeSlideIn">
                  <Message.Header>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Icon name="close" /> Erreur lors de l'enregistrement du
                      cours
                    </div>
                  </Message.Header>
                  <List>
                    <List.Item
                      icon="pencil"
                      content="Le titre du cours ne doit pas être vide."
                      className="fadeSlideIn"
                    />
                  </List>
                </Message>
              </Form.Field>
            )}
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button basic loading={loading} onClick={handleClose}>
          <Icon name="cancel" />
          {creating ? "Annuler" : "Fermer"}
        </Button>
        <Button
          loading={loading}
          onClick={handleSave}
          disabled={_.isEqual(lessonProps, lesson)}
          style={{
            backgroundColor: Colors.positive,
            color: Colors.ghostWhite,
          }}
        >
          <Icon name="checkmark" />
          Sauvegarder
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

LessonModal.defaultProps = {
  creating: false,
  lesson: null,
};

export default LessonModal;
