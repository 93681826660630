import * as React from "react";

import { QuestionType } from "@/types";
import { Colors } from "../../constants";
import { firebaseRequest } from "../../utils";

import { Icon, Segment, SemanticICONS } from "semantic-ui-react";
import QuestionModal from "../Modals/QuestionModal";

interface Props {
  question: QuestionType;
  questionMode: boolean;
}

const QuestionCard = (props: Props) => {
  const { question, questionMode } = props;

  const [step, setStep] = React.useState<number>(1);
  const [openQuestionModal, setOpenQuestionModal] =
    React.useState<boolean>(false);

  const handleQuestionStepChange = React.useCallback(
    (direction: "previous" | "next") => {
      setStep((prev: number) => {
        if (direction === "previous" && prev > 1) {
          return prev - 1;
        }

        return prev + 1;
      });
    },
    []
  );

  const handleQuestionRate = React.useCallback(
    (rating: number) => {
      firebaseRequest.update("questions", question?.id, {
        knowledge: rating,
      });

      setStep(1);
    },
    [question]
  );

  let statusIcon: SemanticICONS, statusColor: string;

  switch (question?.knowledge) {
    case 3:
      statusIcon = "question circle";
      statusColor = Colors.grey;
      break;
    case 2:
      statusIcon = "smile outline";
      statusColor = Colors.positive;
      break;
    case 1:
      statusIcon = "meh outline";
      statusColor = Colors.warn;
      break;
    case 0:
      statusIcon = "frown outline";
      statusColor = Colors.danger;
      break;
  }

  return (
    <Segment raised className="fadeIn" key={`question-${question?.id}`}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Icon
          size="big"
          name={statusIcon}
          style={{ color: statusColor }}
          className="fadeSlideIn"
        />
        &nbsp;&nbsp;
        {step === 1 && (
          <span
            className="fadeSlideIn"
            style={{ fontFamily: "websiteTitle", fontSize: "1.2em" }}
          >
            {question?.question}
          </span>
        )}
        {step === 2 && (
          <span
            className="fadeSlideIn"
            style={{ fontFamily: "websiteTitle", fontSize: "1.2em" }}
          >
            {question?.answer}
          </span>
        )}
        {step === 3 && (
          <span
            className="fadeSlideIn"
            style={{ fontFamily: "websiteTitle", fontSize: "1.2em" }}
          >
            Alors, vous avez su répondre à la question ?
          </span>
        )}
        <span style={{ position: "absolute", right: "1%" }}>
          {!questionMode && (
            <QuestionModal
              open={openQuestionModal}
              onClose={() => setOpenQuestionModal(false)}
              question={question}
              trigger={
                <Icon
                  link
                  size="big"
                  name="edit"
                  onClick={() => setOpenQuestionModal(true)}
                  className="fadeSlideInFromRight"
                  style={{ color: Colors.sageGreenDark }}
                />
              }
            />
          )}
          {step !== 3 && questionMode && (
            <Icon
              link
              size="big"
              name="chevron circle right"
              onClick={() => handleQuestionStepChange("next")}
              className="fadeSlideInFromRight"
              style={{ color: Colors.sageGreenDarker }}
            />
          )}
          {step === 3 && questionMode && (
            <span>
              <Icon
                link
                size="big"
                name="frown outline"
                onClick={() => handleQuestionRate(0)}
                className="fadeSlideIn"
                style={{ color: Colors.danger }}
              />
              &nbsp;&nbsp;
              <Icon
                link
                size="big"
                name="meh outline"
                onClick={() => handleQuestionRate(1)}
                className="fadeSlideIn"
                style={{ color: Colors.warn }}
              />
              &nbsp;&nbsp;
              <Icon
                link
                size="big"
                name="smile outline"
                onClick={() => handleQuestionRate(2)}
                className="fadeSlideIn"
                style={{ color: Colors.positive }}
              />
            </span>
          )}
        </span>
      </div>
    </Segment>
  );
};

export default QuestionCard;
