import { ClassManager } from "@/types";

/**
 * Object allowing the user to work with css classes
 */
const classManager: ClassManager = {
  /**
   * Function allowing the user to add a temporary class to an element
   *
   * @param elementId Id of the element on which you wish to add the class
   * @param className Class you want to add to the element
   * @param timeout Time after which the class will be removed from the input, default : 1000 ms
   */
  addTemporaryClass: (
    elementId: string,
    className: string,
    timeout: number = 1000
  ) => {
    const targetInput = document.getElementById(elementId);

    targetInput.classList.add(className);

    setTimeout(() => {
      targetInput.classList.remove(className);
    }, timeout);
  },
};

export default classManager;
