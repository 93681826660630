import _ from "lodash";

/**
 * Function that checks every input to determine if they respect security rules
 *
 * @param username Modified display name
 * @param email Modified email
 * @param password Modified password
 */
const checkUpdateProfile = (
  username: string,
  email: string,
  password: string
) => {
  const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  const validEmail =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  let errors: { [field: string]: string } = {
    username: null,
    email: null,
    password: null,
  };

  // If the username has been changed
  if (!_.isNil(username)) {
    if (_.isEmpty(username)) {
      errors.username = "Le nom d'utilisateur ne doit pas être vide.";
    } else if (username.length < 3 || username.length > 20) {
      errors.username =
        "Le nom d'utilisateur doit contenir entre 3 et 20 caractères.";
    } else if (specialChars.test(username)) {
      errors.username =
        "Le nom d'utilisateur ne doit pas contenir de caractères spéciaux.";
    }
  }

  // If the email has been changed
  if (!_.isNil(email)) {
    if (_.isEmpty(email)) {
      errors.email = "L'email ne doit pas être vide.";
    } else if (!validEmail.test(email)) {
      errors.email = "Veuillez entrer un email valide.";
    }
  }

  // If the password has been changed
  if (!_.isNil(password)) {
    if (_.isEmpty(password)) {
      errors.password = "Le mot de passe ne doit pas être vide.";
    } else if (password.length < 8) {
      errors.password = "Le mot de passe doit contenir au moins 8 caractères.";
    } else if (
      !specialChars.test(password) ||
      !/[A-Z]/.test(password) ||
      !/[0-9]/.test(password)
    ) {
      errors.password =
        "Le mot de passe doit contenir au moins un caractère spécial, un chiffre et une majuscule.";
    }
  }

  return errors;
};

export default checkUpdateProfile;
