import _ from "lodash";
import * as React from "react";

import { Link, useLocation } from "react-router-dom";

import { signOut } from "firebase/auth";

import { auth } from "../config/firebase-config";
import { AuthContext } from "./AuthProvider";

import { Header, Icon, Menu, Sidebar } from "semantic-ui-react";

import { Colors } from "../constants";
import ConfirmationModal from "./Modals/ConfirmationModal";

import googlePlayImage from "../assets/googlePlayDownload.png";

interface Props {
  children: React.ReactNode;
}

const Navbar = (props: Props) => {
  const { children } = props;
  const { currentUser } = React.useContext(AuthContext);

  const [visible, setVisible] = React.useState<boolean>(false);

  /**
   * Manages the sidebar's visibility
   */
  const handleVisibleChange = React.useCallback(() => {
    setVisible((prev: boolean) => !prev);
  }, []);

  const handleSignout = React.useCallback(() => {
    signOut(auth);
  }, []);

  const currentPath = useLocation();

  return (
    <Sidebar.Pushable>
      <Sidebar
        vertical
        as={Menu}
        animation="overlay"
        icon="labeled"
        direction="left"
        width="thin"
        color="blue"
        visible={visible}
        onHide={() => setVisible(false)}
      >
        <Menu.Item
          as={Link}
          to="/"
          onClick={handleVisibleChange}
          active={currentPath?.pathname === "/"}
        >
          <Icon name="home" />
          Accueil
        </Menu.Item>

        {_.isNil(currentUser) ? (
          <div style={{ position: "absolute", top: "37%" }}>
            <i>
              <Icon name="question circle" />
              <br />
              Connectez-vous pour avoir accès aux autres fonctionnalités !
            </i>
          </div>
        ) : (
          <>
            <Menu.Item
              as={Link}
              to="/lessons"
              onClick={handleVisibleChange}
              active={currentPath?.pathname === "/lessons"}
            >
              <Icon name="book" />
              Cours
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="/deadlines"
              onClick={handleVisibleChange}
              active={currentPath?.pathname === "/deadlines"}
            >
              <Icon name="hourglass half" />
              Deadlines
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="/training"
              onClick={handleVisibleChange}
              active={currentPath?.pathname === "/training"}
            >
              <Icon name="graduation cap" />
              QCM / Révisions
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="/profile"
              onClick={handleVisibleChange}
              active={currentPath?.pathname === "/profile"}
            >
              <Icon name="user" />
              Profil
            </Menu.Item>
          </>
        )}
        <Menu.Item
          as={Link}
          to="/help"
          onClick={handleVisibleChange}
          active={currentPath?.pathname === "/help"}
        >
          <Icon name="question" />
          Aide
        </Menu.Item>
        <div
          style={{
            width: "100%",
            position: "absolute",
            bottom: "1rem",
            fontFamily: "websiteTitle",
            flexDirection: "column",
          }}
        >
          <p
            style={{
              fontSize: "1.7em",
              marginBottom: "1em",
            }}
          >
            ACADEMY
          </p>
          <i>
            Made by <br />
            Samy DJEMILI
          </i>
          <br />
          <br />

          <i style={{ fontSize: "0.6em" }}>
            Illustrations by&nbsp;
            <a target="_blank" rel="noreferrer" href="https://www.storyset.com">
              Storyset
            </a>
          </i>
        </div>
      </Sidebar>

      <Sidebar.Pusher dimmed={visible} style={{ height: "100%" }}>
        <nav
          style={{
            display: "flex",
            justifyContent: "center",
            zIndex: 1000,
            width: "100%",
            height: "8%",
          }}
        >
          {!visible && (
            <>
              <div>
                <Icon
                  className="zoomAnimation fadeSlideIn"
                  name="bars"
                  size="big"
                  inverted
                  onClick={handleVisibleChange}
                  style={{
                    position: "absolute",
                    top: "1rem",
                    left: "1rem",
                    cursor: "pointer",
                  }}
                />
              </div>
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: "8.5%",
                }}
              >
                <a
                  href="https://play.google.com/store/apps/details?id=com.samysteries.academy"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="download on google play"
                    src={googlePlayImage}
                    className="dropShadow fadeSlideIn zoomAnimation2"
                    style={{
                      objectFit: "contain",
                      width: "9em",
                      height: "4em",
                      cursor: "pointer",
                    }}
                  />
                </a>
              </div>
            </>
          )}
          <div
            className="centeredChildren"
            style={{
              position: "absolute",
              top: 0,
              width: "50%",
              height: "4rem",
              background: "white",
              textAlign: "center",
              borderRadius: "0px 0px 50px 50px",
            }}
          >
            {_.isNil(currentUser) ? (
              <Header as="h2" style={{ fontFamily: "websiteTitle" }}>
                Bienvenue sur&nbsp;
                <Link to="/">
                  <span style={{ color: Colors.sageGreenDark }}>ACADEMY</span>
                </Link>
              </Header>
            ) : (
              <Header as="h2" style={{ fontFamily: "websiteTitle" }}>
                Quel plaisir de vous revoir&nbsp;
                <Link
                  to={currentPath?.pathname === "/profile" ? "/" : "/profile"}
                  className="link"
                >
                  <span
                    style={{ color: Colors.sageGreenDark, cursor: "pointer" }}
                  >
                    {currentUser?.displayName}
                  </span>
                </Link>
                &nbsp;!
              </Header>
            )}
          </div>
          <div>
            {_.isNil(currentUser) ? (
              <div className="registerLoginButtons">
                <span className="zoomAnimation" style={{ color: "#fff" }}>
                  <Link to="/register" className="link">
                    Inscription
                  </Link>
                </span>
                <span className="zoomAnimation">
                  <Link to="/login" className="link">
                    Connexion
                  </Link>
                </span>
              </div>
            ) : (
              <ConfirmationModal
                onConfirm={handleSignout}
                confirmButtonLabel="Se déconnecter"
                message="Voulez-vous vraiment vous déconnecter d'Academy ?"
                title={
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <Icon
                      name="sign out"
                      size="large"
                      style={{
                        position: "absolute",
                        left: 20,
                        color: Colors.danger,
                      }}
                    />
                    &nbsp;
                    <span
                      style={{
                        fontFamily: "websiteTitle",
                        fontSize: "1.3em",
                        color: Colors.danger,
                      }}
                    >
                      Confirmation de la déconnexion
                    </span>
                  </div>
                }
                trigger={
                  <Icon
                    className="zoomAnimation"
                    name="sign out"
                    color="red"
                    size="big"
                    inverted
                    style={{
                      position: "absolute",
                      top: "1rem",
                      right: "1rem",
                      cursor: "pointer",
                    }}
                  />
                }
              />
            )}
          </div>
        </nav>
        <div
          style={{
            width: "100%",
            height: "90%",
          }}
        >
          {children}
        </div>
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};

export default Navbar;
