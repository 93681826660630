import { QuestionType } from "@/types";
import _ from "lodash";

const checkUpdateQuestion = (question: QuestionType) => {
  let errors: { [field: string]: string } = {
    lesson: null,
    question: null,
    answer: null,
  };

  // Checking if question is empty
  if (_.isEmpty(question?.question)) {
    errors.question = "La question ne doit pas être vide.";
  }

  // Checking if answer is empty
  if (_.isEmpty(question?.answer)) {
    errors.answer = "La réponse ne doit pas être vide.";
  }

  // Checking if it's linked to a lesson
  if (_.isEmpty(question?.lesson_id)) {
    errors.lesson = "Vous devez sélectionner un cours.";
  }

  return errors;
};

export default checkUpdateQuestion;
