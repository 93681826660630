import _ from "lodash";

import * as React from "react";

import { Colors } from "../../constants";

import { Button, Icon, Modal } from "semantic-ui-react";

interface Props {
  /** Confirmation modal title */
  title?: React.ReactNode;
  /** Messaage displayed */
  message?: React.ReactNode;
  /** Confirmation button label */
  confirmButtonLabel?: string;
  /** Custom trigger */
  trigger: React.ReactNode;
  /** Function called when clicking confirm button */
  onConfirm: Function;
  /** Function called when modal opened */
  onOpen?: Function;
  /** Function called when clicking the cancel button */
  onCancel?: Function;
}

const ConfirmationModal = (props: Props) => {
  const {
    title,
    message,
    confirmButtonLabel,
    trigger,
    onConfirm,
    onOpen,
    onCancel,
  } = props;

  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const handleConfirm = React.useCallback(() => {
    if (_.isFunction(onConfirm)) {
      onConfirm();
    }

    setOpenModal(false);
  }, [onConfirm]);

  const handleOpen = React.useCallback(() => {
    if (_.isFunction(onOpen)) {
      onOpen?.();
    }

    setOpenModal(true);
  }, [onOpen]);

  const handleCancel = React.useCallback(() => {
    if (_.isFunction(onCancel)) {
      onCancel?.();
    }

    setOpenModal(false);
  }, [onCancel]);

  return (
    <Modal
      centered
      closeIcon
      closeOnEscape={false}
      closeOnDimmerClick={false}
      open={openModal}
      trigger={trigger}
      onOpen={handleOpen}
      onClose={handleCancel}
      size="small"
      className="fadeSlideInFromTop"
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <Modal.Description>{message}</Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          icon={<Icon name="close" color="grey" />}
          content="Annuler"
          onClick={handleCancel}
        />
        <Button
          icon="checkmark"
          content={confirmButtonLabel}
          onClick={handleConfirm}
          style={{
            backgroundColor: Colors.danger,
            color: Colors.ghostWhite,
          }}
        />
      </Modal.Actions>
    </Modal>
  );
};

ConfirmationModal.defaultProps = {
  title: "Confirmation de la suppression",
  message: "Voulez-vous vraiment supprimer cet élément ?",
  confirmButtonLabel: "Supprimer",
  onCancel: null,
  onOpen: null,
};

export default ConfirmationModal;
