import { ToastType } from "../enums";
import { toast } from "react-toastify";

const toastNotifier = (toastType: ToastType, message: string) => {
  switch (toastType) {
    case ToastType.success:
      toast.success(message);
      break;
    case ToastType.warn:
      toast.warn(message);
      break;
    case ToastType.error:
      toast.error(message);
      break;
    case ToastType.default:
      toast(message);
      break;
    default:
      break;
  }
};

export default toastNotifier;
