import * as React from "react";

import { Link, Redirect } from "react-router-dom";

import { AuthError, signInWithEmailAndPassword } from "firebase/auth";
import { auth, signInWithGooglePopup } from "../config/firebase-config";

import { Colors } from "../constants";
import { classManager } from "../utils";

import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  Message,
} from "semantic-ui-react";

import { AuthContext } from "../components/AuthProvider";

import loginImage from "../assets/Login-amico.png";
import googleLogo from "../assets/googleLogo.png";

const Login = ({ history }) => {
  const { currentUser } = React.useContext(AuthContext);

  const [error, setError] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState(false);

  const handleLogin = React.useCallback(
    async (event: React.BaseSyntheticEvent) => {
      event.preventDefault();
      setLoading(true);

      const { email, password } = event.target.elements;

      signInWithEmailAndPassword(auth, email.value, password.value).then(
        () => {
          history.push("/lessons");
        },
        (error: AuthError) => {
          setError(true);

          classManager.addTemporaryClass("email", "horizontalShaking");
          classManager.addTemporaryClass("password", "horizontalShaking");

          setLoading(false);
        }
      );
    },
    [history, setError]
  );

  const handleGoogleSignIn = React.useCallback(async () => {
    await signInWithGooglePopup();
  }, []);

  if (currentUser) return <Redirect to="/lessons" />;

  return (
    <div className="background centeredChildren">
      <Grid
        stackable
        className="transparentBackground fadeSlideInFromRight"
        style={{ width: "50%" }}
      >
        <Grid.Column width={8}>
          <Form
            onSubmit={handleLogin}
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
          >
            <Header as="h2" icon textAlign="center">
              <Icon name="user circle" />
              Connexion
            </Header>

            <Form.Field required error={error} style={{ paddingTop: "1rem" }}>
              <label>Email</label>
              <Input
                required
                name="email"
                id="email"
                icon="mail"
                type="email"
                placeholder="john.doe@gmail.com"
                className="dropShadowInput"
              />
            </Form.Field>

            <Form.Field required error={error}>
              <label>Mot de passe</label>
              <Input
                required
                name="password"
                id="password"
                icon="lock"
                type="password"
                placeholder="***********"
                className="dropShadowInput"
              />
            </Form.Field>

            {error && (
              <Form.Field className="dropShadow">
                <Message
                  negative
                  floating
                  className="fadeSlideIn"
                  style={{ textAlign: "center" }}
                >
                  <Message.Header>
                    <Icon name="close" /> Erreur lors de la connexion
                  </Message.Header>
                  <p>Vérifiez vos informations.</p>
                </Message>
              </Form.Field>
            )}

            <Form.Field style={{ paddingTop: "1rem" }}>
              <div className="zoomAnimation2">
                <Button
                  fluid
                  circular
                  size="huge"
                  type="submit"
                  loading={loading}
                  className="dropShadow"
                  style={{
                    backgroundColor: Colors.sageGreenDark,
                    color: Colors.ghostWhite,
                  }}
                >
                  <div>
                    <span>Se connecter</span>
                  </div>
                </Button>
              </div>

              <div>
                <Divider horizontal>OU</Divider>
              </div>
              <div className="zoomAnimation2">
                <Button
                  fluid
                  circular
                  size="huge"
                  onClick={handleGoogleSignIn}
                  loading={loading}
                  className="dropShadow"
                  style={{
                    backgroundColor: Colors.ghostWhite,
                    color: Colors.sageGreenDarker,
                    padding: "10px 10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      fontSize: "1em",
                    }}
                  >
                    <Image src={googleLogo} avatar size="mini" />
                    <p>Se connecter avec google</p>
                  </div>
                </Button>
              </div>
            </Form.Field>

            <Form.Field style={{ paddingTop: 10 }}>
              <Header as="h5" textAlign="center">
                Vous n'avez pas de compte ?
                <Link to="/register">
                  <span
                    style={{
                      color: Colors.sageGreenDark,
                      cursor: "pointer",
                      fontStyle: "italic",
                      textDecoration: "underline",
                      paddingLeft: "0.5rem",
                    }}
                  >
                    Inscrivez-vous
                  </span>
                </Link>
              </Header>
            </Form.Field>
          </Form>
        </Grid.Column>
        <Grid.Column width={8} verticalAlign="middle">
          <div className="dropShadow">
            <Image src={loginImage} alt="Online illustrations by Storyset" />
          </div>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default Login;
