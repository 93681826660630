import * as React from "react";

import { Link } from "react-router-dom";

import { Grid, Icon, IconGroup, List } from "semantic-ui-react";

import { Colors } from "../constants";

import welcomeImage from "../assets/Welcome-bro.png";
import deadlinesScreen from "../assets/deadlinesScreen.png";
import newSubjectScreen1 from "../assets/newSubjectScreen1.png";
import newSubjectScreen2 from "../assets/newSubjectScreen2.png";
import newLessonScreen1 from "../assets/newLessonScreen1.png";
import newLessonScreen2 from "../assets/newLessonScreen2.png";
import lessonScreen from "../assets/lessonScreen.png";
import filteredLessonsScreen from "../assets/filteredLessonsScreen.png";
import questionsScreen from "../assets/questionsScreen.png";
import computerTyping from "../assets/Typing-bro.png";
import newQuestionScreen from "../assets/newQuestionScreen.png";
import questionBegin from "../assets/questionBegin.png";
import questionShowAnswer from "../assets/questionShowAnswer.png";
import questionRate from "../assets/questionRate.png";
import questionsFilteredBySubject from "../assets/questionsFilteredBySubject.png";
import questionsFilteredByLesson from "../assets/questionsFIlteredByLesson.png";
import editionMode from "../assets/editionMode.png";
import questionEditModal from "../assets/questionEditModal.png";

const Help = () => {
  // #region Declarations
  const tableOfContentRef = React.useRef(null);
  const introductionRef = React.useRef(null);
  const deadlinesRef = React.useRef(null);
  const firstDeadlineRef = React.useRef(null);
  const secondDeadlineRef = React.useRef(null);
  const lessonsRef = React.useRef(null);
  const addSubjectRef = React.useRef(null);
  const addLessonRef = React.useRef(null);
  const studyingRef = React.useRef(null);
  const modificationsRef = React.useRef(null);
  const questionsRef = React.useRef(null);
  const addQuestionRef = React.useRef(null);
  const answerQuestionRef = React.useRef(null);
  const filterQuestionsRef = React.useRef(null);
  const editQuestionModeRef = React.useRef(null);

  // #endregion

  // #region Functions

  const smoothScrollTo = (ref) => {
    ref?.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  // #endregion

  return (
    <div className="background">
      <div style={{ overflowY: "scroll", height: "100%", margin: "1.5em" }}>
        <Grid stackable style={{ margin: "1.5em", marginBottom: 0 }}>
          <Grid.Row>
            <Grid.Column width={16} textAlign="center">
              <h1
                id="title"
                style={{ fontSize: "5em" }}
                className="academyTitle fadeSlideInFromTop"
              >
                Guide d'utilisation d'ACADEMY
              </h1>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row verticalAlign="middle">
            <Grid.Column width={8} textAlign="center">
              <img
                alt="welcome sign"
                src={welcomeImage}
                className="dropShadow fadeSlideIn"
                style={{
                  objectFit: "contain",
                  width: "650px",
                  height: "650px",
                }}
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <p className="academyParagraph fadeSlideInFromRight">
                Si vous vous sentez un peu désorienté(e), ne vous inquiétez pas,
                vous êtes au bon endroit ! <br />
                Plongez dans notre guide détaillé qui regorge de conseils et
                d'astuces pour une utilisation fluide et efficace d'Academy !
              </p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <h1
                ref={tableOfContentRef}
                className="academyTitle"
                style={{ color: Colors.creamLight }}
              >
                Sommaire
              </h1>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={5}>
              <List animated style={{ width: 400, marginLeft: "2em" }}>
                <List.Item onClick={() => smoothScrollTo(introductionRef)}>
                  <span className="academyParagraph">
                    <Icon name="caret right" />
                    <a href="#introduction" className="link">
                      Introduction
                    </a>
                  </span>
                </List.Item>

                <List.Item
                  style={{ marginTop: "0.75em" }}
                  onClick={() => smoothScrollTo(deadlinesRef)}
                >
                  <span className="academyParagraph">
                    <Icon name="caret right" />
                    <a href="#deadlines" className="link">
                      Deadlines
                    </a>
                  </span>
                </List.Item>

                <List.Item
                  style={{ marginLeft: "10%" }}
                  onClick={() => smoothScrollTo(firstDeadlineRef)}
                >
                  <span className="academyParagraph">
                    <Icon name="caret right" />
                    <a href="#firstDeadline" className="link">
                      Première deadline
                    </a>
                  </span>
                </List.Item>

                <List.Item
                  style={{ marginLeft: "10%" }}
                  onClick={() => smoothScrollTo(secondDeadlineRef)}
                >
                  <span className="academyParagraph">
                    <Icon name="caret right" />
                    <a href="#secondDeadline" className="link">
                      Deuxième deadline
                    </a>
                  </span>
                </List.Item>

                <List.Item
                  style={{ marginTop: "0.75em" }}
                  onClick={() => smoothScrollTo(lessonsRef)}
                >
                  <span className="academyParagraph">
                    <Icon name="caret right" />
                    <a href="#lessons" className="link">
                      Cours
                    </a>
                  </span>
                </List.Item>

                <List.Item
                  style={{ marginLeft: "10%" }}
                  onClick={() => smoothScrollTo(addSubjectRef)}
                >
                  <span className="academyParagraph">
                    <Icon name="caret right" />
                    <a href="#addSubject" className="link">
                      Ajouter une matière
                    </a>
                  </span>
                </List.Item>

                <List.Item
                  style={{ marginLeft: "10%" }}
                  onClick={() => smoothScrollTo(addLessonRef)}
                >
                  <span className="academyParagraph">
                    <Icon name="caret right" />
                    <a href="#addLesson" className="link">
                      Ajouter un cours
                    </a>
                  </span>
                </List.Item>

                <List.Item
                  style={{ marginLeft: "10%" }}
                  onClick={() => smoothScrollTo(studyingRef)}
                >
                  <span className="academyParagraph">
                    <Icon name="caret right" />
                    <a href="#studying" className="link">
                      Révisions
                    </a>
                  </span>
                </List.Item>
                <List.Item
                  style={{ marginLeft: "10%" }}
                  onClick={() => smoothScrollTo(modificationsRef)}
                >
                  <span className="academyParagraph">
                    <Icon name="caret right" />
                    <a href="#modifications" className="link">
                      Modifications
                    </a>
                  </span>
                </List.Item>
                <List.Item
                  style={{ marginTop: "0.75em" }}
                  onClick={() => smoothScrollTo(questionsRef)}
                >
                  <span className="academyParagraph">
                    <Icon name="caret right" />
                    <a href="#modifications" className="link">
                      Questions / QCM
                    </a>
                  </span>
                </List.Item>
                <List.Item
                  style={{ marginLeft: "10%" }}
                  onClick={() => smoothScrollTo(addQuestionRef)}
                >
                  <span className="academyParagraph">
                    <Icon name="caret right" />
                    <a href="#addQuestion" className="link">
                      Ajouter une question
                    </a>
                  </span>
                </List.Item>
                <List.Item
                  style={{ marginLeft: "10%" }}
                  onClick={() => smoothScrollTo(answerQuestionRef)}
                >
                  <span className="academyParagraph">
                    <Icon name="caret right" />
                    <a href="#addQuestion" className="link">
                      Répondre aux questions
                    </a>
                  </span>
                </List.Item>
                <List.Item
                  style={{ marginLeft: "10%" }}
                  onClick={() => smoothScrollTo(filterQuestionsRef)}
                >
                  <span className="academyParagraph">
                    <Icon name="caret right" />
                    <a href="#filterQuestions" className="link">
                      Filtrer les questions
                    </a>
                  </span>
                </List.Item>
                <List.Item
                  style={{ marginLeft: "10%" }}
                  onClick={() => smoothScrollTo(editQuestionModeRef)}
                >
                  <span className="academyParagraph">
                    <Icon name="caret right" />
                    <a href="#editQuestions" className="link">
                      Mode édition
                    </a>
                  </span>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{ marginTop: "5em", marginBottom: "5em" }}>
            <Grid.Column width={16} textAlign="center" verticalAlign="middle">
              <h1
                ref={introductionRef}
                className="academyTitle"
                onClick={() => smoothScrollTo(tableOfContentRef)}
                style={{
                  cursor: "pointer",
                  color: Colors.creamLight,
                }}
              >
                Introduction
              </h1>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <div style={{ marginLeft: "3em", marginRight: "3em" }}>
              <p className="academyParagraph">
                Academy est un assistant vous permettant de suivre un planning
                de révision régulier, idéal pour celles et ceux qui ne savent
                pas par où commencer, Academy vous fait votre propre planning !
              </p>

              <p className="academyParagraph">
                Le fonctionnement est simple et intuitif, créez vos matières,
                vos cours puis vos questions, celles-ci vous aideront à retenir
                les points les plus importants de vos cours.
              </p>

              <p className="academyParagraph">
                Et encore, c'est pas tout, des deadlines sont là pour vous
                indiquer les cours les plus prioritaires, vous avez juste à les
                renseigner, ou alors vous pouvez rester avec celles qui sont
                présentes par défaut, on a pensé à tout pour vous !
              </p>
            </div>
          </Grid.Row>

          <Grid.Row style={{ marginTop: "7em", marginBottom: "5em" }}>
            <Grid.Column width={16} textAlign="center">
              <h1
                ref={deadlinesRef}
                className="academyTitle"
                onClick={() => smoothScrollTo(tableOfContentRef)}
                style={{ cursor: "pointer", color: Colors.creamLight }}
              >
                Deadlines
              </h1>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <div style={{ marginLeft: "3em", marginRight: "3em" }}>
              <p className="academyParagraph">
                Première étape avec Academy, les deadlines ! <br />
                Elles sont essentielles car elles vont permettre à Academy de
                vous créer un planning personnalisé et adapté à vos capacités de
                révision !
              </p>
            </div>

            <div
              className="centeredChildren"
              style={{ marginTop: "2em", flexDirection: "column" }}
            >
              <Link
                to="/deadlines"
                style={{
                  display: "flex",
                  alignItem: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  alt="deadlines screen"
                  src={deadlinesScreen}
                  className="dropShadow fadeSlideIn"
                  style={{
                    objectFit: "contain",
                    width: "60%",
                  }}
                />
              </Link>
              <i className="academyLegend">
                Cliquez sur l'image pour vous rendre sur l'écran des deadlines
              </i>
            </div>
          </Grid.Row>

          <Grid.Row style={{ marginTop: "3em" }}>
            <Grid.Column width={8} textAlign="justified">
              <h2
                ref={firstDeadlineRef}
                className="academySubtitle"
                onClick={() => smoothScrollTo(tableOfContentRef)}
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                Première deadline
              </h2>

              <p
                className="academyParagraph"
                style={{
                  marginTop: "2em",
                  marginLeft: "2em",
                  marginRight: "2em",
                }}
              >
                La première deadline permet de fixer une première limite,
                au-delà du nombre de jour que vous renseignez, vous pouvez
                commencer à reviser les cours qui seront marqués d'un&nbsp;
                <span style={{ color: Colors.warn }}>
                  smiley orange <Icon name="meh outline" />
                </span>
                .
              </p>
            </Grid.Column>

            <Grid.Column width={8} textAlign="justified">
              <h2
                ref={secondDeadlineRef}
                className="academySubtitle"
                onClick={() => smoothScrollTo(tableOfContentRef)}
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                Deuxième deadline
              </h2>
              <p
                className="academyParagraph"
                style={{
                  marginTop: "2em",
                  marginLeft: "2em",
                  marginRight: "2em",
                }}
              >
                La deuxième deadline permet de fixer LA date limite de révision
                de vos cours, au-delà du nombre de jour que vous renseignez,
                vous devrez réviser les cours qui seront marqués d'un&nbsp;
                <span style={{ color: Colors.danger }}>
                  smiley rouge <Icon name="frown outline" />
                </span>
                , sinon vous risquez fortement de les oublier . . .
              </p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{ marginTop: "10em", marginBottom: "3em" }}>
            <Grid.Column width={16} textAlign="center">
              <h1
                ref={lessonsRef}
                className="academyTitle"
                onClick={() => smoothScrollTo(tableOfContentRef)}
                style={{ cursor: "pointer", color: Colors.creamLight }}
              >
                Cours
              </h1>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <h2
              ref={addSubjectRef}
              className="academySubtitle"
              onClick={() => smoothScrollTo(tableOfContentRef)}
              style={{
                textAlign: "center",
                cursor: "pointer",
                marginLeft: "1em",
              }}
            >
              Ajouter une matière
            </h2>

            <div
              style={{
                marginLeft: "3em",
                marginRight: "3em",
                marginTop: "3em",
              }}
            >
              <p className="academyParagraph">
                Tout d'abord, vous allez devoir renseigner les différentes
                matières que vous avez afin de mieux structurer vos cours.
              </p>

              <p className="academyParagraph">
                Cliquez sur le bouton&nbsp;&nbsp;
                <span style={{ color: Colors.cream }}>
                  <IconGroup>
                    <Icon name="folder" />
                    <Icon
                      corner="bottom right"
                      name="add"
                      style={{ color: Colors.positive }}
                    />
                  </IconGroup>
                  &nbsp;Nouvelle matière
                </span>
                &nbsp; puis remplissez les champs nécessaires.
              </p>
            </div>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <div
                className="centeredChildren"
                style={{ flexDirection: "column" }}
              >
                <Link
                  to="/lessons"
                  style={{
                    display: "flex",
                    alignItem: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    alt="lesson screen add subject"
                    src={newSubjectScreen1}
                    className="dropShadow fadeSlideIn"
                    style={{
                      objectFit: "contain",
                      width: "100%",
                    }}
                  />
                </Link>
                <i className="academyLegend">
                  Cliquez sur l'image pour essayer d'ajouter une matière
                </i>
              </div>
            </Grid.Column>
            <Grid.Column width={8}>
              <div
                className="centeredChildren"
                style={{ flexDirection: "column" }}
              >
                <Link
                  to="/lessons"
                  style={{
                    display: "flex",
                    alignItem: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    alt="lesson screen add subject modal"
                    src={newSubjectScreen2}
                    className="dropShadow fadeSlideIn"
                    style={{
                      objectFit: "contain",
                      width: "100%",
                    }}
                  />
                </Link>
                <i className="academyLegend">
                  Cliquez sur l'image pour essayer d'ajouter une matière
                </i>
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{ marginTop: "7em" }}>
            <h2
              ref={addLessonRef}
              className="academySubtitle"
              onClick={() => smoothScrollTo(tableOfContentRef)}
              style={{
                cursor: "pointer",
                marginLeft: "1em",
              }}
            >
              Ajouter un cours
            </h2>
          </Grid.Row>
          <Grid.Row>
            <div
              style={{
                marginLeft: "3em",
                marginRight: "3em",
                marginTop: "2em",
              }}
            >
              <p className="academyParagraph">
                Après avoir ajouté vos matières, vous pouvez passer à la
                création de vos cours.
              </p>

              <p className="academyParagraph">
                Cliquez sur le bouton&nbsp;&nbsp;
                <span style={{ color: Colors.creamLight }}>
                  <IconGroup>
                    <Icon name="book" />
                    <Icon
                      corner="bottom right"
                      name="add"
                      style={{ color: Colors.positive }}
                    />
                  </IconGroup>
                  &nbsp;Nouveau cours
                </span>
                &nbsp; puis remplissez les champs nécessaires.
                <br />
                Si vous avez beaucoup de matières, vous pouvez écrire le nom de
                la matière recherchée dans le champs correspondant et une
                suggestion apparaîtra !
              </p>
            </div>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={8}>
              <div
                className="centeredChildren"
                style={{ flexDirection: "column" }}
              >
                <Link
                  to="/lessons"
                  style={{
                    display: "flex",
                    alignItem: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    alt="lesson screen add lesson"
                    src={newLessonScreen1}
                    className="dropShadow fadeSlideIn"
                    style={{
                      objectFit: "contain",
                      width: "100%",
                    }}
                  />
                </Link>
                <i className="academyLegend">
                  Cliquez sur l'image pour essayer d'ajouter un cours
                </i>
              </div>
            </Grid.Column>
            <Grid.Column width={8}>
              <div
                className="centeredChildren"
                style={{ flexDirection: "column" }}
              >
                <Link
                  to="/lessons"
                  style={{
                    display: "flex",
                    alignItem: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    alt="lesson screen add lesson modal"
                    src={newLessonScreen2}
                    className="dropShadow fadeSlideIn"
                    style={{
                      objectFit: "contain",
                      width: "100%",
                    }}
                  />
                </Link>
                <i className="academyLegend">
                  Cliquez sur l'image pour essayer d'ajouter un cours
                </i>
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{ marginTop: "7em" }}>
            <h2
              ref={studyingRef}
              className="academySubtitle"
              onClick={() => smoothScrollTo(tableOfContentRef)}
              style={{
                cursor: "pointer",
                marginLeft: "1em",
              }}
            >
              Révisions
            </h2>
          </Grid.Row>

          <Grid.Row verticalAlign="middle">
            <Grid.Column width={8}>
              <div
                style={{
                  marginLeft: "3em",
                  marginRight: "3em",
                  marginTop: "2em",
                }}
              >
                <p className="academyParagraph">
                  La partie la plus intéressante, grâce à ça, fini le stress de
                  la planification et de l'organisation, Academy le fait à votre
                  place !
                </p>
                <p className="academyParagraph">
                  Tout ce que vous aurez à faire, c'est vous connecter à
                  l'application, regarder votre planning et réviser les cours
                  qui vous semblent prioritaires.
                </p>

                <p className="academyParagraph">
                  Une fois un cours révisé, vous aurez juste à modifier sa
                  dernière date de révision et votre planning se mettra à jour
                  automatiquement !
                </p>
              </div>
            </Grid.Column>

            <Grid.Column width={8}>
              <div
                className="centeredChildren"
                style={{ flexDirection: "column" }}
              >
                <Link
                  to="/lessons"
                  style={{
                    display: "flex",
                    alignItem: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    alt="lesson screen with many lessons and subjects"
                    src={lessonScreen}
                    className="dropShadow fadeSlideIn"
                    style={{
                      objectFit: "contain",
                      width: "100%",
                    }}
                  />
                </Link>
                <i className="academyLegend">
                  Cliquez sur l'image pour vous rendre sur l'écran des cours
                </i>
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{ marginTop: "5em" }} verticalAlign="middle">
            <Grid.Column width={8}>
              <div
                className="centeredChildren"
                style={{ flexDirection: "column" }}
              >
                <Link
                  to="/lessons"
                  style={{
                    display: "flex",
                    alignItem: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    alt="lessons filtered and sorted by subjects"
                    src={filteredLessonsScreen}
                    className="dropShadow fadeSlideIn"
                    style={{
                      objectFit: "contain",
                      width: "100%",
                    }}
                  />
                </Link>
                <i className="academyLegend">
                  Cliquez sur l'image pour essayer les filtres
                </i>
              </div>
            </Grid.Column>
            <Grid.Column width={8}>
              <div
                style={{
                  marginLeft: "3em",
                  marginRight: "3em",
                  marginTop: "2em",
                }}
              >
                <p className="academyParagraph">
                  L'avantage que vous avez avec Academy, c'est que vos cours
                  sont triés par ordre de priorité :{" "}
                  <Icon name="frown outline" style={{ color: Colors.danger }} />
                  &rarr;&nbsp;
                  <Icon name="meh outline" style={{ color: Colors.warn }} />
                  &rarr;&nbsp;
                  <Icon
                    name="smile outline"
                    style={{ color: Colors.positive }}
                  />
                </p>
                <p className="academyParagraph">
                  Pour avoir plus de visibilité sur vos cours, vous pouvez les
                  trier, vous avez juste à cliquer sur une matière et Academy se
                  charge du reste !
                </p>

                <p className="academyParagraph">
                  Pour pousser vos révisions, vous pouvez même ajouter des
                  questions afin de retenir les points les plus importants de
                  vos cours, pour en savoir plus, rendez-vous dans la section{" "}
                  <u
                    style={{ color: Colors.creamLight, cursor: "pointer" }}
                    onClick={() => smoothScrollTo(questionsRef)}
                  >
                    Questions
                  </u>
                  .
                </p>
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{ marginTop: "7em" }}>
            <h2
              ref={modificationsRef}
              className="academySubtitle"
              onClick={() => smoothScrollTo(tableOfContentRef)}
              style={{
                cursor: "pointer",
                marginLeft: "1em",
              }}
            >
              Modifications
            </h2>
          </Grid.Row>

          <Grid.Row verticalAlign="middle">
            <Grid.Column width={8}>
              <div
                style={{
                  marginLeft: "3em",
                  marginRight: "3em",
                }}
              >
                <p className="academyParagraph">
                  Comment modifier un cours ou une matière ?
                </p>
                <p className="academyParagraph">
                  Pour modifier un{" "}
                  <span style={{ color: Colors.creamLight }}>cours</span>,
                  cliquez dessus, une nouvelle fenêtre apparaîtra et vous
                  pourrez entrer de nouvelles informations, notamment de
                  nouvelles dates de révisions !
                </p>

                <p className="academyParagraph">
                  Pour les matières, passez votre souris sur une matière, le
                  message "
                  <span style={{ color: Colors.creamLight }}>
                    <Icon name="edit" />
                    Modifier
                  </span>
                  " apparaîtra, vous aurez juste à cliquer dessus.
                </p>
              </div>
            </Grid.Column>

            <Grid.Column width={8}>
              <div className="centeredChildren">
                <img
                  alt="lesson screen with many lessons and subjects"
                  src={computerTyping}
                  className="dropShadow fadeSlideIn"
                  style={{
                    objectFit: "contain",
                    width: "65%",
                  }}
                />
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{ marginTop: "10em", marginBottom: "3em" }}>
            <Grid.Column width={16} textAlign="center">
              <h1
                ref={questionsRef}
                className="academyTitle"
                onClick={() => smoothScrollTo(tableOfContentRef)}
                style={{ cursor: "pointer", color: Colors.creamLight }}
              >
                Questions / QCM
              </h1>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{ marginTop: "5em" }}>
            <h2
              ref={addQuestionRef}
              className="academySubtitle"
              onClick={() => smoothScrollTo(tableOfContentRef)}
              style={{
                cursor: "pointer",
                marginLeft: "1em",
              }}
            >
              Ajouter une question
            </h2>
          </Grid.Row>

          <Grid.Row>
            <div
              style={{
                marginLeft: "3em",
                marginRight: "3em",
                marginTop: "2em",
              }}
            >
              <p className="academyParagraph">
                Academy vous donne la possibilité de créer des questions afin
                que vous puissiez retenir les points les plus importants de vos
                cours.
              </p>
              <p className="academyParagraph">
                Cliquez sur le bouton&nbsp;&nbsp;
                <span style={{ color: Colors.creamLight }}>
                  <IconGroup>
                    <Icon name="question circle" />
                    <Icon
                      corner="bottom right"
                      name="add"
                      style={{ color: Colors.positive }}
                    />
                  </IconGroup>
                  &nbsp;Nouvelle question
                </span>
                &nbsp; puis remplissez les champs nécessaires.
                <br />
                Encore une fois, on a pensé à tout, si vous avez beaucoup de
                cours, ne vous embêtez pas à scroller, écrivez le nom du cours
                et une suggestion apparaîtra !
              </p>
              <p className="academyParagraph">
                Encore mieux, si vous voulez créer plusieurs questions
                d'affilée, cliquez sur le cours concerné dans le menu à gauche
                puis cliquez ensuite sur&nbsp;
                <span style={{ color: Colors.creamLight }}>
                  <IconGroup>
                    <Icon name="question circle" />
                    <Icon
                      corner="bottom right"
                      name="add"
                      style={{ color: Colors.positive }}
                    />
                  </IconGroup>
                  &nbsp;Nouvelle question
                </span>
                , le cours sera automatiquement sélectionné !
              </p>
            </div>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={8}>
              <div
                className="centeredChildren"
                style={{ flexDirection: "column" }}
              >
                <Link
                  to="/training"
                  style={{
                    display: "flex",
                    alignItem: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    alt="questions screen"
                    src={questionsScreen}
                    className="dropShadow fadeSlideIn"
                    style={{
                      objectFit: "contain",
                      width: "100%",
                    }}
                  />
                </Link>
                <i className="academyLegend">
                  Cliquez sur l'image vous rendre sur l'écran des questions
                </i>
              </div>
            </Grid.Column>
            <Grid.Column width={8}>
              <div
                className="centeredChildren"
                style={{ flexDirection: "column" }}
              >
                <Link
                  to="/training"
                  style={{
                    display: "flex",
                    alignItem: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    alt="new question modal"
                    src={newQuestionScreen}
                    className="dropShadow fadeSlideIn"
                    style={{
                      objectFit: "contain",
                      width: "100%",
                    }}
                  />
                </Link>
                <i className="academyLegend">
                  Cliquez sur l'image pour essayer d'ajouter une question
                </i>
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{ marginTop: "7em" }}>
            <h2
              ref={answerQuestionRef}
              className="academySubtitle"
              onClick={() => smoothScrollTo(tableOfContentRef)}
              style={{
                cursor: "pointer",
                marginLeft: "1em",
              }}
            >
              Répondre aux questions
            </h2>
          </Grid.Row>

          <Grid.Row>
            <div
              style={{
                marginLeft: "3em",
                marginRight: "3em",
                marginTop: "2em",
              }}
            >
              <p className="academyParagraph">
                Vous avez ajouté vos questions, maintenant comment y répondre ?
              </p>

              <p className="academyParagraph">
                Cliquez une première fois sur le bouton&nbsp;
                <span style={{ color: Colors.sageGreenDarker }}>
                  <Icon name="chevron circle right" />
                </span>
                &nbsp;pour afficher la réponse, puis cliquez une deuxième fois
                sur{" "}
                <span style={{ color: Colors.sageGreenDarker }}>
                  <Icon name="chevron circle right" />
                </span>
                pour noter votre capacité à avoir répondu facilement (ou non) à
                la question.
              </p>

              <p className="academyParagraph">
                Les questions marquées par un&nbsp;
                <span style={{ color: Colors.grey }}>
                  <Icon name="question circle" />
                </span>
                &nbsp;correspondent aux questions auxquelles vous n'avez pas
                encore répondu.
              </p>
            </div>
          </Grid.Row>

          <Grid.Row style={{ display: "flex", justifyContent: "center" }}>
            <Grid.Column width={5}>
              <div
                className="centeredChildren"
                style={{ flexDirection: "column" }}
              >
                <img
                  alt="question display"
                  src={questionBegin}
                  className="dropShadow fadeSlideIn"
                  style={{
                    objectFit: "contain",
                    width: "100%",
                  }}
                />
                <i className="academyLegend">Affichage de la question</i>
              </div>
            </Grid.Column>
            <Grid.Column width={5}>
              <div
                className="centeredChildren"
                style={{ flexDirection: "column" }}
              >
                <img
                  alt="question answer display"
                  src={questionShowAnswer}
                  className="dropShadow fadeSlideIn"
                  style={{
                    objectFit: "contain",
                    width: "100%",
                  }}
                />
                <i className="academyLegend">Affichage de la réponse</i>
              </div>
            </Grid.Column>
            <Grid.Column width={5}>
              <div
                className="centeredChildren"
                style={{ flexDirection: "column" }}
              >
                <img
                  alt="question rate display"
                  src={questionRate}
                  className="dropShadow fadeSlideIn"
                  style={{
                    objectFit: "contain",
                    width: "100%",
                  }}
                />
                <i className="academyLegend">Notation de la question</i>
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{ marginTop: "7em" }}>
            <h2
              ref={filterQuestionsRef}
              className="academySubtitle"
              onClick={() => smoothScrollTo(tableOfContentRef)}
              style={{
                cursor: "pointer",
                marginLeft: "1em",
              }}
            >
              Filtrer les questions
            </h2>
          </Grid.Row>

          <Grid.Row>
            <div
              style={{
                marginLeft: "3em",
                marginRight: "3em",
                marginTop: "2em",
              }}
            >
              <p className="academyParagraph">
                Pour filtrer vos questions, vous avez deux options.
              </p>

              <p className="academyParagraph">
                La première consiste à filtrer vos questions par matière
                uniquement, vous offrant ainsi la possibilité de réviser de
                façon globale. Pour cela, rien de plus simple, vous avez juste à
                cliquer sur une matière dans la liste présente à gauche de
                l'écran.
              </p>

              <p className="academyParagraph">
                La deuxième option vous offre un filtrage plus précis, au lieu
                de trier vos questions par matière, vous pouvez les trier par
                cours uniquement. Et vous l'aurez compris, c'est aussi simple
                que précédemment, vous avez juste à cliquer sur un cours dans la
                liste déroulante affichée lorsque vous cliquez sur une matière !
              </p>
            </div>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={8}>
              <div
                className="centeredChildren"
                style={{ flexDirection: "column" }}
              >
                <img
                  alt="questions filtered by subject"
                  src={questionsFilteredBySubject}
                  className="dropShadow fadeSlideIn"
                  style={{
                    objectFit: "contain",
                    width: "100%",
                  }}
                />
                <i className="academyLegend">Questions filtrées par matière</i>
              </div>
            </Grid.Column>
            <Grid.Column width={8}>
              <div
                className="centeredChildren"
                style={{ flexDirection: "column" }}
              >
                <img
                  alt="questions filtered by lesson"
                  src={questionsFilteredByLesson}
                  className="dropShadow fadeSlideIn"
                  style={{
                    objectFit: "contain",
                    width: "100%",
                  }}
                />

                <i className="academyLegend">Questions filtrées par cours</i>
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{ marginTop: "7em" }}>
            <h2
              ref={editQuestionModeRef}
              className="academySubtitle"
              onClick={() => smoothScrollTo(tableOfContentRef)}
              style={{
                cursor: "pointer",
                marginLeft: "1em",
              }}
            >
              Mode édition
            </h2>
          </Grid.Row>

          <Grid.Row>
            <div
              style={{
                marginLeft: "3em",
                marginRight: "3em",
                marginTop: "2em",
              }}
            >
              <p className="academyParagraph">
                Vous souhaitez modifier ou supprimer une question ?
              </p>

              <p className="academyParagraph">
                Le mode édition vous permet d'apporter des modifications à
                chacune de vos questions et même de pouvoir les supprimer.{" "}
                <br />
                Pour passer en mode édition, cliquez sur le bouton{" "}
                <span style={{ color: "black" }}>
                  <Icon name="question circle" />
                  Question
                  <Icon name="arrow right" />
                  Edition
                </span>{" "}
                .<br />
                Pour modifier une question, cliquez sur l'icône{" "}
                <Icon name="edit" style={{ color: Colors.sageGreenDark }} />.
              </p>

              <p className="academyParagraph">
                Si vous voulez quitter le mode édition, il vous suffira juste
                d'appuyer sur le bouton{" "}
                <span style={{ color: "black" }}>
                  <Icon name="pencil" />
                  Edition
                  <Icon name="arrow right" />
                  Question
                </span>{" "}
                .
              </p>
            </div>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={8}>
              <div
                className="centeredChildren"
                style={{ flexDirection: "column" }}
              >
                <img
                  alt="edition mode for questions"
                  src={editionMode}
                  className="dropShadow fadeSlideIn"
                  style={{
                    objectFit: "contain",
                    width: "100%",
                  }}
                />
                <i className="academyLegend">Mode édition</i>
              </div>
            </Grid.Column>
            <Grid.Column width={8}>
              <div
                className="centeredChildren"
                style={{ flexDirection: "column" }}
              >
                <img
                  alt="edition modal for questions"
                  src={questionEditModal}
                  className="dropShadow fadeSlideIn"
                  style={{
                    objectFit: "contain",
                    width: "100%",
                  }}
                />

                <i className="academyLegend">
                  Fenêtre de modification d'une question
                </i>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
};

export default Help;
