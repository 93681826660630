import * as React from "react";
import { Icon, Popup } from "semantic-ui-react";
import SubjectModal from "../Modals/SubjectModal";
import { SubjectType } from "@/types";

interface Props {
  trigger: React.ReactNode;
  subject: SubjectType;
  onDelete?: Function;
}

const SubjectPopup = (props: Props) => {
  const { trigger, subject, onDelete } = props;

  const [openSubjectModal, setOpenSubjectModal] =
    React.useState<boolean>(false);

  return (
    <SubjectModal
      onClose={() => setOpenSubjectModal(false)}
      onDelete={onDelete}
      open={openSubjectModal}
      subject={subject}
      trigger={
        <Popup
          pinned
          hoverable
          on="hover"
          position="left center"
          trigger={trigger}
          className="fadeSlideInFromRight"
        >
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setOpenSubjectModal(true)}
          >
            <Icon name="edit" />
            Modifier
          </div>
        </Popup>
      }
    />
  );
};

SubjectPopup.defaultProps = {
  onDelete: null,
};

export default SubjectPopup;
