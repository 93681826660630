import _ from "lodash";

import * as React from "react";

import PropTypes from "prop-types";
import { Card, Icon, SemanticICONS } from "semantic-ui-react";

import dayjs from "dayjs";

import { LessonType, QuestionType } from "@/types";
import { AuthContext } from "../AuthProvider";
import { Colors } from "../../constants";
import LessonModal from "../Modals/LessonModal";

interface Props {
  lesson: LessonType;
  activeSemester: number;
}

const LessonCard = (props: Props) => {
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const { lesson, activeSemester } = props || {};
  const { title, times_studied, last_time_studied, id } = lesson || {};

  const { deadlines, questions } = React.useContext(AuthContext);

  // Va permettre de calculer le nombre de jours depuis la dernière révision
  const lastTimeStudiedDate = dayjs(last_time_studied);
  const dayCounter = Math.round(
    dayjs().diff(lastTimeStudiedDate.format("YYYY-MM-DD"), "day", false)
  );

  const formatedLastStudyDate = new Date(lesson?.last_time_studied);

  let statusIcon: SemanticICONS, statusColor: string;

  if (dayCounter <= deadlines[0]?.lower) {
    statusIcon = "smile outline";
    statusColor = Colors.positive;
  } else if (
    dayCounter > deadlines?.[0]?.lower &&
    dayCounter <= deadlines?.[0]?.upper
  ) {
    statusIcon = "meh outline";
    statusColor = Colors.warn;
  } else {
    statusIcon = "frown outline";
    statusColor = Colors.danger;
  }

  return (
    <LessonModal
      open={openModal}
      lesson={lesson}
      activeSemester={activeSemester}
      onClose={() => setOpenModal(false)}
      trigger={
        <Card
          onClick={() => setOpenModal(true)}
          link
          raised
          className="fadeIn"
          style={{
            cursor: "pointer",
            backgroundColor: Colors.creamLight,
            height: 180,
          }}
        >
          <Card.Content>
            <Card.Header
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div
                style={{
                  width: "85%",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {title}
              </div>
              <div style={{ width: "10%" }}>
                <Icon
                  name={statusIcon}
                  size="large"
                  style={{ color: statusColor }}
                />
              </div>
            </Card.Header>
            <Card.Meta style={{ paddingTop: "1em" }}>
              <div style={{ paddingBottom: "0.3em" }}>
                <Icon name="eye" />
                <i style={{ fontSize: "1em" }}>
                  Révisé <b>{times_studied}</b> fois
                </i>
              </div>
              <div style={{ paddingBottom: "0.3em" }}>
                <Icon name="question" />
                <i>
                  <b>
                    {_.size(
                      _.filter(
                        questions,
                        (question: QuestionType) => question.lesson_id === id
                      )
                    )}
                  </b>
                  &nbsp;questions
                </i>
              </div>
              <div style={{ paddingBottom: "0.3em" }}>
                <Icon name="calendar alternate" />
                <i>
                  Révisé le{" "}
                  <b style={{ color: statusColor }}>
                    {formatedLastStudyDate?.toLocaleDateString()}
                  </b>
                </i>
              </div>
            </Card.Meta>
          </Card.Content>
          <Card.Content extra>
            <div
              style={{
                textAlign: "center",
              }}
            >
              <Icon name="clock" style={{ color: statusColor }} />
              &nbsp;
              <i>
                <b>{dayCounter}</b> jour(s)
              </i>
            </div>
          </Card.Content>
        </Card>
      }
    />
  );
};

LessonCard.propTypes = {
  lesson: PropTypes.shape({
    studentId: PropTypes.string,
    subject: PropTypes.string,
    title: PropTypes.string,
    times_studied: PropTypes.number,
    last_time_studied: PropTypes.string,
    note: PropTypes.string,
    semester: PropTypes.number,
  }),
};

LessonCard.defaultProps = {
  lesson: {
    studentId: 0,
    subject: "",
    title: "Lesson",
    times_studied: 0,
    last_time_studied: "08/23/2023",
    note: "My lesson",
    semester: 1,
  },
};

export default LessonCard;
