import _ from "lodash";
import * as React from "react";

import { LessonType, QuestionType } from "@/types";
import { ToastType } from "../../enums";
import {
  checkUpdateQuestion,
  classManager,
  firebaseRequest,
  toastNotifier,
} from "../../utils";
import { Colors } from "../../constants";

import { AuthContext } from "../AuthProvider";

import {
  Button,
  Form,
  Icon,
  Input,
  List,
  Message,
  Modal,
} from "semantic-ui-react";

import ConfirmationModal from "./ConfirmationModal";

interface Props {
  creating?: boolean;
  question?: QuestionType;
  currentLessonId?: string;
  trigger: React.ReactNode;
  onClose: Function;
  open: boolean;
}

const QuestionModal = (props: Props) => {
  const {
    creating,
    currentLessonId,
    question: questionProps,
    trigger,
    onClose,
    open,
  } = props;

  const { currentUser, lessons } = React.useContext(AuthContext);

  const [question, setQuestion] = React.useState(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [errors, setErrors] = React.useState<{ [field: string]: string }>({
    lesson: null,
    question: null,
    answer: null,
  });

  React.useEffect(() => {
    if (!creating) {
      setQuestion(questionProps);
    } else {
      setQuestion({
        student_id: currentUser?.uid,
        question: "",
        answer: "",
        lesson_id: currentLessonId,
        knowledge: 3,
      });
    }
  }, [creating, currentLessonId, currentUser?.uid, questionProps]);

  const handleChange = React.useCallback((e, { name, value }) => {
    setQuestion((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleDeleteQuestion = React.useCallback(() => {
    firebaseRequest.delete("questions", questionProps?.id);

    if (_.isFunction(onClose)) onClose();

    toastNotifier(
      ToastType.error,
      `Vous venez de supprimer la question : ${questionProps?.question}`
    );
  }, [onClose, questionProps?.id, questionProps?.question]);

  const handleResetInformation = React.useCallback(() => {
    setQuestion({
      student_id: currentUser?.uid,
      question: creating ? "" : questionProps?.question,
      answer: creating ? "" : questionProps?.answer,
      lesson_id: creating ? currentLessonId : questionProps?.lesson_id,
      knowledge: creating ? 3 : questionProps?.knowledge,
    });

    setErrors({
      question: null,
      answer: null,
      lesson: null,
    });
  }, [creating, currentLessonId, currentUser?.uid, questionProps]);

  const handleClose = React.useCallback(() => {
    if (_.isFunction(onClose)) onClose();

    handleResetInformation();

    setErrors({
      question: null,
      answer: null,
      lesson: null,
    });
  }, [handleResetInformation, onClose]);

  const handleSave = React.useCallback(() => {
    setLoading(true);

    let currentErrors = checkUpdateQuestion(question);

    setErrors(currentErrors);

    if (
      _.isNil(currentErrors?.question) &&
      _.isNil(currentErrors?.answer) &&
      _.isNil(currentErrors?.lesson)
    ) {
      if (creating) {
        firebaseRequest.post("questions", question);
      } else {
        firebaseRequest.update("questions", questionProps?.id, {
          question: question?.question,
          answer: question?.answer,
          lesson_id: question?.lesson_id,
        });
      }

      handleClose();
      handleResetInformation();

      toastNotifier(
        ToastType.success,
        `Vous avez ${creating ? "ajouté" : "modifié"} la question : ${
          question?.question
        }`
      );
    } else {
      if (currentErrors?.lesson) {
        classManager.addTemporaryClass("lesson", "horizontalShaking");
      }

      if (currentErrors?.question) {
        classManager.addTemporaryClass("question", "horizontalShaking");
      }

      if (currentErrors?.answer) {
        classManager.addTemporaryClass("answer", "horizontalShaking");
      }
    }

    setLoading(false);
  }, [
    creating,
    handleClose,
    handleResetInformation,
    question,
    questionProps?.id,
  ]);

  const lessonDropdownOptions = _.map(
    _.orderBy(lessons, ["semester", "title"], ["asc", "asc"]),
    (lesson: LessonType) => ({
      key: `key-lesson-${lesson?.id}-${lesson?.title}`,
      text: `S${lesson?.semester} - ${lesson?.title}`,
      value: lesson?.id,
    })
  );

  return (
    <Modal
      closeIcon
      closeOnDimmerClick={false}
      closeOnEscape={false}
      open={open}
      trigger={trigger}
      onClose={handleClose}
      size="small"
      className="fadeSlideInFromTop"
    >
      <Modal.Header>
        <div style={{ width: "100%", textAlign: "center" }}>
          <Icon
            name="question circle"
            size="large"
            style={{
              position: "absolute",
              left: 20,
              color: Colors.sageGreenDark,
            }}
          />
          &nbsp;
          <span
            style={{
              fontFamily: "websiteTitle",
              fontSize: "1.3em",
              color: Colors.sageGreenDark,
            }}
          >
            {creating ? "Nouvelle question" : question?.question}
          </span>
        </div>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description style={{ width: "100%" }}>
          <Form>
            <Form.Group>
              <Form.Field width={creating ? 16 : 13} error={errors?.lesson}>
                <label>Matière</label>
                <Form.Dropdown
                  search
                  selection
                  name="lesson_id"
                  id="lesson"
                  placeholder="Choisissez un cours"
                  noResultsMessage="Ce cours n'existe pas."
                  value={question?.lesson_id}
                  onChange={handleChange}
                  options={lessonDropdownOptions}
                />
              </Form.Field>

              {!creating && (
                <Form.Field
                  width={3}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <ConfirmationModal
                    onConfirm={handleDeleteQuestion}
                    message={
                      <div>
                        <p>
                          Vous êtes sur le point de supprimer la question :{" "}
                          <span
                            style={{
                              fontStyle: "italic",
                              fontWeight: "bold",
                              fontFamily: "websiteTitle",
                              fontSize: "1.2em",
                              paddingLeft: "1em",
                            }}
                          >
                            {questionProps?.question}
                          </span>
                        </p>
                      </div>
                    }
                    title={
                      <div style={{ width: "100%", textAlign: "center" }}>
                        <Icon
                          name="trash alternate"
                          size="large"
                          style={{
                            position: "absolute",
                            left: 20,
                            color: Colors.danger,
                          }}
                        />
                        &nbsp;
                        <span
                          style={{
                            fontFamily: "websiteTitle",
                            fontSize: "1.3em",
                            color: Colors.danger,
                          }}
                        >
                          Confirmation de la suppression
                        </span>
                      </div>
                    }
                    trigger={
                      <span className="zoomAnimation2">
                        <Icon
                          link
                          size="big"
                          name="trash alternate"
                          style={{ color: Colors.danger }}
                        />
                      </span>
                    }
                  />
                </Form.Field>
              )}
            </Form.Group>

            <Form.Field width={16} error={errors?.question}>
              <label>Question</label>
              <Input
                id="question"
                name="question"
                icon="question"
                placeholder="Combien d'os y a-t-il dans le crâne ?"
                type="text"
                onChange={handleChange}
                value={question?.question}
              />
            </Form.Field>

            <Form.Field error={errors?.answer}>
              <label>Réponse</label>
              <Input
                id="answer"
                name="answer"
                icon="check"
                placeholder="Il y a 8 os dans le crâne."
                type="text"
                onChange={handleChange}
                value={question?.answer}
              />
            </Form.Field>

            {(errors.lesson || errors.question || errors.answer) && (
              <Form.Field>
                <Message negative className="fadeSlideIn">
                  <Message.Header>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Icon name="close" /> Erreur lors de{" "}
                      {creating ? "la création" : "l'enregistrement"} de la
                      question
                    </div>
                  </Message.Header>
                  <List>
                    {_.map(_.entries(errors), ([field, message]) => {
                      let iconName: string;

                      switch (field) {
                        case "lesson":
                          iconName = "book";
                          break;
                        case "question":
                          iconName = "question";
                          break;
                        case "answer":
                          iconName = "check";
                          break;
                        default:
                          break;
                      }

                      return (
                        message && (
                          <List.Item
                            icon={iconName}
                            content={message}
                            className="fadeSlideIn"
                          />
                        )
                      );
                    })}
                  </List>
                </Message>
              </Form.Field>
            )}
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button basic loading={loading} onClick={handleClose}>
          <Icon name="cancel" />
          {creating ? "Annuler" : "Fermer"}
        </Button>
        <Button
          loading={loading}
          onClick={handleSave}
          disabled={_.isEqual(questionProps, question)}
          style={{
            backgroundColor: Colors.positive,
            color: Colors.ghostWhite,
          }}
        >
          <Icon name="checkmark" />
          Sauvegarder
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

QuestionModal.defaultProps = {
  creating: false,
  question: null,
  currentLessonId: null,
};

export default QuestionModal;
