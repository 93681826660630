import _ from "lodash";
import * as React from "react";

import {
  Accordion,
  Grid,
  Header,
  Icon,
  IconGroup,
  List,
} from "semantic-ui-react";

import { Colors } from "../constants";
import { LessonType, QuestionType, SubjectType } from "@/types";

import { QuestionCard, QuestionModal } from "../components";
import { AuthContext } from "../components/AuthProvider";

import manThinkingImage from "../assets/Man-thinking-bro.png";
import studyingImage from "../assets/Studying-bro.png";

const Training = () => {
  const { subjects, lessons, questions } = React.useContext(AuthContext);

  const [activeSubjectPanel, setActiveSubjectPanel] =
    React.useState<string>(null);

  const [activeLessonItem, setActiveLessonItem] = React.useState<string>(null);

  const [activeKnowledgeFilter, setActiveKnowledgeFilter] =
    React.useState<number>(null);

  const [openNewQuestionModal, setOpenNewQuestionModal] =
    React.useState<boolean>(false);

  const [questionMode, setQuestionMode] = React.useState<boolean>(true);

  const handleQuestionModeToggle = React.useCallback(() => {
    setQuestionMode((prev: boolean) => !prev);
  }, []);

  const handleSubjectClick = React.useCallback((subjectId: string) => {
    setActiveLessonItem(null);

    setActiveSubjectPanel((prev: string) =>
      prev === subjectId ? null : subjectId
    );
  }, []);

  const handleLessonClick = React.useCallback((lessonId: string) => {
    setActiveLessonItem((prev: string) =>
      prev === lessonId ? null : lessonId
    );
  }, []);

  const handleKnowledgeFilterClick = React.useCallback((knowledgeLevel) => {
    setActiveKnowledgeFilter((prev: number) =>
      prev === knowledgeLevel ? null : knowledgeLevel
    );
  }, []);

  return (
    <div className="background">
      <Grid
        columns={2}
        style={{
          margin: "1em",
          height: "100%",
        }}
      >
        <Grid.Column width={4} style={{ height: "100%" }}>
          <div
            className="dropShadow"
            style={{ height: "100%", maxHeight: "100%", overflowY: "scroll" }}
          >
            <Accordion styled className="fadeSlideIn">
              {_.map(
                _.orderBy(subjects, "title", "asc"),
                (subject: SubjectType) => {
                  const filteredLessons = _.filter(
                    lessons,
                    (lesson: LessonType) => lesson?.subject_id === subject?.id
                  );

                  const totalSubjectQuestions = _.filter(
                    questions,
                    (question: QuestionType) =>
                      _.includes(
                        _.map(filteredLessons, (lec: LessonType) => lec?.id),
                        question?.lesson_id
                      )
                  );

                  const LessonList = (
                    <List animated divided className="fadeSlideIn">
                      {_.map(
                        _.orderBy(filteredLessons, "title", "asc"),
                        (filteredLesson: LessonType) => {
                          const lessonFilteredQuestions = _.filter(
                            questions,
                            (question: QuestionType) =>
                              question?.lesson_id === filteredLesson?.id
                          );

                          return (
                            <List.Item
                              active={activeLessonItem === filteredLesson?.id}
                              key={`lesson-${filteredLesson?.id}`}
                              icon={
                                <Icon
                                  name="book"
                                  className={
                                    activeLessonItem === filteredLesson?.id
                                      ? "selectedListItem"
                                      : ""
                                  }
                                />
                              }
                              header={
                                <Header
                                  as="h4"
                                  content={filteredLesson?.title}
                                  style={{
                                    fontFamily: "websiteTitle",
                                    fontSize: "1.2em",
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                  }}
                                  className={
                                    activeLessonItem === filteredLesson?.id
                                      ? "selectedListItem"
                                      : ""
                                  }
                                />
                              }
                              description={
                                _.size(lessonFilteredQuestions) !== 0 && (
                                  <span
                                    style={{
                                      fontFamily: "websiteTitle",
                                      fontStyle: "italic",
                                      color:
                                        activeLessonItem === filteredLesson?.id
                                          ? Colors.sageGreenDark
                                          : "grey",
                                    }}
                                  >
                                    <Icon name="question circle" />
                                    {_.size(lessonFilteredQuestions)}{" "}
                                    question(s)
                                  </span>
                                )
                              }
                              onClick={() =>
                                handleLessonClick(filteredLesson?.id)
                              }
                              style={{
                                display: "flex",
                                marginLeft: "1em",
                                cursor: "pointer",
                                alignItems: "center",
                              }}
                            />
                          );
                        }
                      )}
                    </List>
                  );

                  return (
                    <>
                      <Accordion.Title
                        active={activeSubjectPanel === subject?.id}
                        onClick={() => handleSubjectClick(subject?.id)}
                        style={{
                          fontFamily: "websiteTitle",
                          fontSize: "1.3em",
                          color:
                            activeSubjectPanel === subject?.id
                              ? Colors.sageGreenDark
                              : "",
                        }}
                        key={`subject-${subject?.id}`}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span className="fadeSlideIn">
                            <Icon name="dropdown" />
                            {subject?.title}
                          </span>
                          {_.size(filteredLessons) !== 0 && (
                            <span className="fadeIn">
                              {_.size(totalSubjectQuestions)}&nbsp;
                              <Icon name="question circle" />
                              &nbsp;&nbsp;&nbsp;
                              {_.size(filteredLessons)}&nbsp;
                              <Icon name="book" />
                            </span>
                          )}
                        </div>
                      </Accordion.Title>
                      <Accordion.Content
                        active={activeSubjectPanel === subject?.id}
                      >
                        {LessonList}
                      </Accordion.Content>
                    </>
                  );
                }
              )}
            </Accordion>
          </div>
        </Grid.Column>

        <Grid.Column width={9} style={{ height: "100%", width: "100%" }}>
          {_.size(
            _.filter(questions, (question: QuestionType) =>
              _.includes(
                _.map(
                  _.filter(
                    lessons,
                    (lesson: LessonType) =>
                      lesson?.subject_id === activeSubjectPanel
                  ),
                  (lec: LessonType) => lec?.id
                ),
                question?.lesson_id
              )
            )
          ) === 0 && activeSubjectPanel ? (
            <div
              style={{
                height: "100%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <img
                alt="man thinking"
                src={studyingImage}
                className="dropShadow fadeIn"
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "80%",
                }}
              />
              <span
                className="fadeIn"
                style={{
                  fontFamily: "websiteTitle",
                  color: Colors.ghostWhite,
                  fontSize: "2em",
                }}
              >
                Vous n'avez pas encore créé de questions dans{" "}
                {activeLessonItem ? "ce cours" : "cette matière"} !
              </span>
            </div>
          ) : (
            activeSubjectPanel && (
              <div
                style={{
                  paddingLeft: "1.5em",
                  paddingRight: "1.5em",
                  height: "100%",
                  maxHeight: "100%",
                  overflowY: "scroll",
                }}
              >
                {!_.includes([0, 1, 2, 3], activeKnowledgeFilter)
                  ? _.map(
                      _.orderBy(
                        _.filter(questions, (question: QuestionType) => {
                          if (activeLessonItem) {
                            return question?.lesson_id === activeLessonItem;
                          }

                          return _.includes(
                            _.map(
                              _.filter(
                                lessons,
                                (lesson: LessonType) =>
                                  lesson?.subject_id === activeSubjectPanel
                              ),
                              (lec: LessonType) => lec?.id
                            ),
                            question?.lesson_id
                          );
                        }),
                        ["knowledge", "question"],
                        ["desc", "asc"]
                      ),
                      (question: QuestionType) => (
                        <QuestionCard
                          key={`question-${question?.id}`}
                          question={question}
                          questionMode={questionMode}
                        />
                      )
                    )
                  : _.map(
                      _.orderBy(
                        _.filter(questions, (question: QuestionType) => {
                          if (activeLessonItem) {
                            return (
                              question?.lesson_id === activeLessonItem &&
                              (question?.knowledge === activeKnowledgeFilter ||
                                question?.knowledge === 3)
                            );
                          }

                          return (
                            _.includes(
                              _.map(
                                _.filter(
                                  lessons,
                                  (lesson: LessonType) =>
                                    lesson?.subject_id === activeSubjectPanel
                                ),
                                (lec: LessonType) => lec?.id
                              ),
                              question?.lesson_id
                            ) &&
                            (question?.knowledge === activeKnowledgeFilter ||
                              question?.knowledge === 3)
                          );
                        }),
                        ["knowledege", "question"],
                        ["desc", "asc"]
                      ),
                      (question: QuestionType) => (
                        <QuestionCard
                          key={`question-${question?.id}`}
                          question={question}
                          questionMode={questionMode}
                        />
                      )
                    )}
              </div>
            )
          )}

          {!activeSubjectPanel && !activeLessonItem && (
            <div
              style={{
                height: "100%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <img
                alt="man thinking"
                src={manThinkingImage}
                className="dropShadow fadeIn"
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "80%",
                }}
              />
              <span
                className="fadeIn"
                style={{
                  fontFamily: "websiteTitle",
                  color: Colors.ghostWhite,
                  fontSize: "2em",
                }}
              >
                Commencez par sélectionner une matière
              </span>
            </div>
          )}
        </Grid.Column>

        <Grid.Column
          width={3}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {activeSubjectPanel && (
            <div
              className="dropShadow fadeSlideInFromRight"
              style={{
                width: "100%",
                padding: "1em",
                marginLeft: "1em",
                borderRadius: 50,
                backgroundColor: Colors.ghostWhite,
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Icon
                className="fadeSlideIn zoomAnimation2"
                size="huge"
                name="frown outline"
                onClick={() => handleKnowledgeFilterClick(0)}
                style={{
                  color: `${Colors.danger}${
                    activeKnowledgeFilter === 0 ? "" : 50
                  }`,
                  cursor: "pointer",
                }}
              />
              <Icon
                className="fadeSlideIn zoomAnimation2"
                size="huge"
                name="meh outline"
                onClick={() => handleKnowledgeFilterClick(1)}
                style={{
                  color: `${Colors.warn}${
                    activeKnowledgeFilter === 1 ? "" : 50
                  }`,
                  cursor: "pointer",
                }}
              />
              <Icon
                className="fadeSlideIn zoomAnimation2"
                size="huge"
                name="smile outline"
                onClick={() => handleKnowledgeFilterClick(2)}
                style={{
                  color: `${Colors.positive}${
                    activeKnowledgeFilter === 2 ? "" : 50
                  }`,
                  cursor: "pointer",
                }}
              />
            </div>
          )}

          <QuestionModal
            creating
            currentLessonId={activeLessonItem}
            open={openNewQuestionModal}
            onClose={() => setOpenNewQuestionModal(false)}
            trigger={
              <div
                onClick={() => setOpenNewQuestionModal(true)}
                className="dropShadow fadeSlideInFromRight zoomAnimation2 centeredChildren"
                style={{
                  width: "90%",
                  padding: "1em",
                  marginTop: activeSubjectPanel ? "1.5em" : "",
                  marginLeft: "1em",
                  borderRadius: 50,
                  cursor: "pointer",
                  backgroundColor: Colors.creamLight,
                }}
              >
                <IconGroup size="big">
                  <Icon name="question circle" />
                  <Icon
                    corner="bottom right"
                    name="add"
                    style={{ color: Colors.positive, paddingLeft: "15%" }}
                  />
                </IconGroup>
                &nbsp; &nbsp;
                <span style={{ fontFamily: "websiteTitle", fontSize: "1.2em" }}>
                  Nouvelle question
                </span>
              </div>
            }
          />

          <div
            onClick={handleQuestionModeToggle}
            className="dropShadow fadeSlideInFromRight zoomAnimation2 centeredChildren"
            style={{
              width: "90%",
              padding: "1em",
              marginTop: "1.5em",
              marginLeft: "1em",
              borderRadius: 50,
              cursor: "pointer",
              backgroundColor: Colors.ghostWhite,
            }}
          >
            <Icon
              size="big"
              name={questionMode ? "question circle" : "pencil"}
            />
            <span style={{ fontFamily: "websiteTitle", fontSize: "1.2em" }}>
              {questionMode ? "Question" : "Edition"}
              <Icon name="arrow right" />
              {questionMode ? "Edition" : "Question"}
            </span>
          </div>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default Training;
