import _ from "lodash";
import { DeadlineType } from "@/types";

const checkUpdateDeadlines = (deadlines: DeadlineType) => {
  let errors: { [field: string]: string } = {
    lower: null,
    upper: null,
    logic: null,
  };

  // Checking if lower is empty
  if (_.isEmpty(deadlines?.lower.toString())) {
    errors.lower = "La première deadline ne doit pas être vide.";
  }

  // Checking if upper is empty
  if (_.isEmpty(deadlines?.upper.toString())) {
    errors.upper = "La deuxième deadline ne doit pas être vide.";
  }

  // Checking if lower > upper
  if (
    !_.isEmpty(deadlines?.lower.toString()) &&
    !_.isEmpty(deadlines?.upper.toString()) &&
    deadlines?.lower >= deadlines?.upper
  ) {
    errors.logic = "La première deadline doit être inférieure à la deuxième.";
  }

  return errors;
};

export default checkUpdateDeadlines;
