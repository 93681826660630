import * as React from "react";

import { User, onAuthStateChanged } from "firebase/auth";
import { auth } from "../config/firebase-config";
import { firebaseRequest } from "../utils";
import { DeadlineType, LessonType, QuestionType, SubjectType } from "@/types";

import { cardio } from "ldrs";

export const AuthContext = React.createContext<{
  currentUser: User;
  lessons: Array<LessonType>;
  subjects: Array<SubjectType>;
  questions: Array<QuestionType>;
  deadlines: Array<DeadlineType>;
}>({
  currentUser: null,
  deadlines: [],
  subjects: [],
  lessons: [],
  questions: [],
});

const AuthProvider = ({ children }) => {
  cardio.register();

  const [currentUser, setCurrentUser] = React.useState(null);

  const [loading, setLoading] = React.useState<boolean>(false);

  const [lessons, setLessons] = React.useState<Array<LessonType>>(null);
  const [subjects, setSubjects] = React.useState<Array<SubjectType>>(null);
  const [deadlines, setDeadlines] = React.useState<Array<DeadlineType>>(null);
  const [questions, setQuestions] = React.useState<Array<QuestionType>>(null);

  React.useEffect(() => {
    onAuthStateChanged(auth, setCurrentUser);
  }, []);

  React.useEffect(() => {
    if (currentUser) {
      setLoading(true);

      // Fetching deadlines
      firebaseRequest.getWhere(
        "deadlines",
        setDeadlines,
        "student_id",
        currentUser.uid
      );

      // Fetching subjects
      firebaseRequest.getWhere(
        "subjects",
        setSubjects,
        "student_id",
        currentUser.uid
      );

      // Fetching lessons
      firebaseRequest.getWhere(
        "lessons",
        setLessons,
        "student_id",
        currentUser.uid
      );

      // Fetching questions
      firebaseRequest.getWhere(
        "questions",
        setQuestions,
        "student_id",
        currentUser.uid
      );

      setLoading(false);
    }
  }, [currentUser]);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        deadlines,
        subjects,
        lessons,
        questions,
      }}
    >
      {loading ? (
        <div
          className="centeredChildren background"
          style={{ flexDirection: "column" }}
        >
          <l-cardio size="200" stroke={10} speed="1.3" color="white" />
          <p
            style={{
              fontFamily: "websiteTitle",
              paddingTop: 20,
              color: "white",
              fontSize: "2em",
            }}
          >
            Chargement de vos données ...
          </p>
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
