import _ from "lodash";
import * as React from "react";

import { Link, Redirect } from "react-router-dom";

import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  List,
  Message,
} from "semantic-ui-react";

import { Colors } from "../constants";
import { UserInformation } from "@/types";
import { checkUpdateProfile, classManager, firebaseRequest } from "../utils";

import {
  AuthError,
  UserCredential,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";

import { auth, signInWithGooglePopup } from "../config/firebase-config";

import registerImage from "../assets/Register-amico.png";
import googleLogo from "../assets/googleLogo.png";
import { AuthContext } from "../components/AuthProvider";

const Register = ({ history }) => {
  const { currentUser } = React.useContext(AuthContext);

  const [errors, setErrors] = React.useState<{ [field: string]: string }>({
    username: null,
    email: null,
    password: null,
  });

  const [loading, setLoading] = React.useState(false);

  const handleGoogleSignIn = React.useCallback(async () => {
    await signInWithGooglePopup();
  }, []);

  /**
   * Fonction permettant de créer le compte d'un utilisateur
   */
  const handleRegister = React.useCallback(
    async (event: React.BaseSyntheticEvent) => {
      event.preventDefault();
      setLoading(true);

      const { username, email, password } = event.target.elements;

      let currentErrors = checkUpdateProfile(
        username.value,
        email.value,
        password.value
      );

      setErrors(currentErrors);

      // Checking if username already used
      await firebaseRequest
        .getWhereWithReturn("userInformation", "username", username.value)
        .then((existingUsers: UserInformation[]) => {
          if (!_.isEmpty(existingUsers)) {
            currentErrors.username = "Ce nom d'utilisateur existe déjà.";
            setErrors((prev) => ({
              ...prev,
              username: "Ce nom d'utilisateur existe déjà.",
            }));
          }
        });

      if (currentErrors.username) {
        classManager.addTemporaryClass("username", "horizontalShaking");
      }

      if (currentErrors.email) {
        classManager.addTemporaryClass("email", "horizontalShaking");
      }

      if (currentErrors.password) {
        classManager.addTemporaryClass("password", "horizontalShaking");
      }

      setLoading(false);

      if (
        _.isNil(currentErrors.username) &&
        _.isNil(currentErrors.email) &&
        _.isNil(currentErrors.password)
      ) {
        createUserWithEmailAndPassword(auth, email.value, password.value).then(
          (userCredentials: UserCredential) => {
            updateProfile(userCredentials.user, {
              displayName: username.value,
            });

            firebaseRequest.postWithId(
              "userInformation",
              userCredentials?.user?.uid,
              {
                creationDate: userCredentials?.user?.metadata?.creationTime,
                displayName: username.value,
                photoUrl: "https://api.dicebear.com/7.x/adventurer/svg",
              }
            );

            setLoading(false);
            history.push("/login");
          },
          // Gestion des erreurs
          (error: AuthError) => {
            switch (error.code) {
              case "auth/invalid-email":
                setErrors((prev) => ({
                  ...prev,
                  email: "Veuillez entrer un email valide.",
                }));
                classManager.addTemporaryClass("email", "horizontalShaking");
                break;

              case "auth/email-already-in-use":
                setErrors((prev) => ({
                  ...prev,
                  email: "Email déjà utilisé.",
                }));
                classManager.addTemporaryClass("email", "horizontalShaking");
                break;

              case "auth/weak-password":
                setErrors((prev) => ({
                  ...prev,
                  password: "Veuillez choisir un mot de passe plus robuste.",
                }));
                classManager.addTemporaryClass("password", "horizontalShaking");
                break;

              default:
                break;
            }

            setLoading(false);
          }
        );
      }
    },
    [history]
  );

  if (currentUser) return <Redirect to="/lessons" />;

  return (
    <div className="centeredChildren background">
      <Grid
        className="transparentBackground fadeSlideIn"
        style={{ width: "50%" }}
        stackable
      >
        <Grid.Column width={8}>
          <Form
            onSubmit={handleRegister}
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
          >
            <Header as="h2" icon textAlign="center">
              <Icon name="users" />
              Inscription
              {/* <Header.Subheader>
                Rejoignez-nous et libérez vous du stress de la planification et
                des révisions !
              </Header.Subheader> */}
            </Header>

            <Form.Field
              required
              error={errors.username}
              style={{ paddingTop: "1rem" }}
            >
              <label>Nom d'utilisateur</label>
              <Input
                required
                id="username"
                name="username"
                icon="user"
                type="text"
                placeholder="John DOE"
                className="dropShadowInput"
              />
            </Form.Field>

            <Form.Field required error={errors.email}>
              <label>Email</label>
              <Input
                required
                name="email"
                id="email"
                icon="mail"
                type="email"
                placeholder="john.doe@gmail.com"
                className="dropShadowInput"
              />
            </Form.Field>

            <Form.Field required error={errors.password}>
              <label>Mot de passe</label>
              <Input
                required
                icon="lock"
                name="password"
                id="password"
                type="password"
                placeholder="***********"
                className="dropShadowInput"
              />
            </Form.Field>

            {(errors.username || errors.email || errors.password) && (
              <Form.Field className="dropShadow">
                <Message negative className="fadeSlideIn">
                  <Message.Header>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Icon name="close" /> Erreur lors de l'inscription
                    </div>
                  </Message.Header>
                  <List>
                    {_.map(_.entries(errors), ([field, message]) => {
                      let iconName: string;

                      switch (field) {
                        case "username":
                          iconName = "user";
                          break;
                        case "email":
                          iconName = "mail";
                          break;
                        case "password":
                          iconName = "lock";
                          break;
                        default:
                          break;
                      }

                      return (
                        message && (
                          <List.Item
                            icon={iconName}
                            content={message}
                            className="fadeSlideIn"
                          />
                        )
                      );
                    })}
                  </List>
                </Message>
              </Form.Field>
            )}

            <Form.Field style={{ paddingTop: "1rem" }}>
              <div className="zoomAnimation2">
                <Button
                  fluid
                  circular
                  size="huge"
                  type="submit"
                  className="dropShadow"
                  loading={loading}
                  style={{
                    backgroundColor: Colors.sageGreenDark,
                    color: Colors.ghostWhite,
                  }}
                >
                  <div>S'inscrire</div>
                </Button>
              </div>
              <div>
                <Divider horizontal>OU</Divider>
              </div>
              <div className="zoomAnimation2">
                <Button
                  fluid
                  circular
                  size="huge"
                  className="dropShadow"
                  onClick={handleGoogleSignIn}
                  loading={loading}
                  style={{
                    backgroundColor: Colors.ghostWhite,
                    color: Colors.sageGreenDarker,
                    padding: "10px 10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      fontSize: "1em",
                    }}
                  >
                    <Image src={googleLogo} avatar size="mini" />
                    <p>Se connecter avec google</p>
                  </div>
                </Button>
              </div>
            </Form.Field>

            <Form.Field style={{ paddingTop: 10 }}>
              <Header as="h5" textAlign="center">
                Déjà membre ?
                <Link to="/login">
                  <span
                    style={{
                      color: Colors.sageGreenDark,
                      cursor: "pointer",
                      fontStyle: "italic",
                      textDecoration: "underline",
                      paddingLeft: "0.5rem",
                    }}
                  >
                    Connectez-vous
                  </span>
                </Link>
              </Header>
            </Form.Field>
          </Form>
        </Grid.Column>
        <Grid.Column width={8} verticalAlign="middle">
          <div className="dropShadow">
            <Image src={registerImage} alt="People illustrations by Storyset" />
          </div>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default Register;
