import _ from "lodash";

import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

import { FirebaseRequest } from "@/types";
import { database } from "../config/firebase-config";

const firebaseRequest: FirebaseRequest = {
  /**
   * Get classique (pas en temps réel)
   *
   * @param {string} table
   * @param {function} stateSetter
   */
  get: async (table, stateSetter) => {
    // On récupère la collection associée à la table passée en paramètre
    const collectionRef = collection(database, table);

    // On récupère les documents qui constituent la table
    const data = await getDocs(collectionRef);

    // On met à jour le state qui va recevoir les informations
    stateSetter(_.map(data.docs, (doc) => ({ ...doc.data(), id: doc.id })));
  },

  /**
   * Get permettant une condition where et en temps réel
   * @param {string} table
   * @param {function} stateSetter
   * @param {string} field
   * @param {string} condition
   */
  getWhere: async (table, stateSetter, field, condition) => {
    // On récupère la collection associée à la table passée en paramètre
    const collectionRef = collection(database, table);

    // On écrit la requête
    const q = query(collectionRef, where(field, "==", condition));

    // Pour avoir les mises à jour en temps réel
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const unsub = onSnapshot(q, (querySnapshot) => {
      const items = [];

      querySnapshot.forEach((doc) => {
        items.push({ ...doc.data(), id: doc.id });
      });

      stateSetter(items);
    });
  },

  /**
   * Get avec un return
   * @param table
   * @param field
   * @param condition
   */
  getWhereWithReturn: async (table, field, condition) => {
    const items = [];
    // On récupère la collection associée à la table passée en paramètre
    const collectionRef = collection(database, table);

    // On écrit la requête
    const q = query(collectionRef, where(field, "==", condition));

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      items.push({ ...doc.data(), id: doc.id });
    });

    return items;
  },

  /**
   * Get sur un enregistrement
   * @param table Table visée
   * @param stateSetter Fonction de remplissage de state
   * @param id  Identifiant de l'enregistrement à récupérer
   */
  getById: async (table, stateSetter, id) => {
    // On récupère la référence du document qui nous intéresse
    const documentRef = doc(database, table, id);

    // On récupère le document pointé par la référence
    const data = await getDoc(documentRef);

    stateSetter({ ...data.data() });
  },

  /**
   * Post classique
   * @param {string} table de l'enregistrement à créer
   * @param {object} structuredData : objet contenant les données à créer
   */
  post: async (table, structuredData) => {
    const collectionRef = collection(database, table);

    await addDoc(collectionRef, structuredData);
  },

  /**
   * Post avec un id défini
   * @param {string} table de l'enregistrement à créer
   * @param {string} id de l'enregistrement à créer
   * @param {object} structuredData : objet contenant les données à créer
   */
  postWithId: async (table, id, structuredData) => {
    const collectionRef = doc(database, table, id);

    await setDoc(collectionRef, structuredData);
  },

  /**
   * Update classique
   * @param {string} table contenant l'enregistrement à update
   * @param {string} id de l'enregistrement à update
   * @param {object} structuredData : objet contenant les données modifiées
   */
  update: async (table, id, structuredData) => {
    // On récupère l'enregistrement qu'on veut update
    const tableDocument = doc(database, table, id);

    await updateDoc(tableDocument, structuredData);
  },

  /**
   *
   * @param {string} table contenant l'enregistrement à delete
   * @param {string} id de l'enregistrement à delete
   */
  delete: async (table, id) => {
    // On récupère l'enregistrement qu'on veut delete
    const tableDocument = doc(database, table, id);

    await deleteDoc(tableDocument);
  },

  /**
   * Delete avec un where
   * @param {string} table faisant l'objet du delete
   * @param {string} field sur lequel on filtre le delete
   * @param {string} condition : valeur pour le filtre du delete
   */
  deleteWhere: async (table, field, condition) => {
    // On récupère la collection associée à la table passée en paramètre
    const collectionRef = collection(database, table);

    // On écrit la requête
    const q = query(collectionRef, where(field, "==", condition));

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const unsub = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        firebaseRequest.delete(table, doc.id);
      });
    });
  },
};

export default firebaseRequest;
