import * as React from "react";

import { Route } from "react-router";
import { BrowserRouter } from "react-router-dom";

import { ToastContainer } from "react-toastify";

import { AuthProvider, Navbar, PrivateRoute } from "./components";
import {
  Home,
  Register,
  Login,
  Profile,
  Lessons,
  Deadlines,
  Training,
  Help,
} from "./pages";

const App = () => (
  <AuthProvider>
    <BrowserRouter>
      <Navbar>
        <Route exact path="/" component={Home} />
        <Route exact path="/help" component={Help} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <PrivateRoute exact path="/profile" component={Profile} />
        <PrivateRoute exact path="/lessons" component={Lessons} />
        <PrivateRoute exact path="/deadlines" component={Deadlines} />
        <PrivateRoute exact path="/training" component={Training} />
      </Navbar>
    </BrowserRouter>
    <ToastContainer
      draggable
      closeOnClick
      pauseOnHover
      pauseOnFocusLoss
      position="top-right"
      autoClose={2500}
      hideProgressBar={false}
      rtl={false}
      theme="colored"
    />
  </AuthProvider>
);

export default App;
