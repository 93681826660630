const Colors = {
  cream: "#FFF8DE",
  creamLight: "#FFF4E9",

  sageGreen: "#99A98F",
  sageGreenDark: "#4B6A70",
  sageGreenDarker: "#2F4858",
  sageGreenLight: "#C1D0B5",
  sageGreenLighter: "#DBEAD2",

  danger: "#FF2E2E",
  warn: "#F98903",
  positive: "#339C5E",
  blue: "#007AFF",

  ghostWhite: "#F8F8FF",
  snow: "#FFFAFA",
  aliceBlue: "#F0F8FF",
  ivory: "#FFFFF0",

  grey: "#9A9A9A",

  // Black variants
  charcoal: "#36454f",

  lavender: "#FAF5FF",
};

export default Colors;
