import _ from "lodash";
import * as React from "react";

import { Form, Grid, Header, Icon, Input } from "semantic-ui-react";

import { ToastType } from "../enums";
import { Colors } from "../constants";
import { ProfileInformationType } from "@/types";
import { checkUpdateProfile, classManager, toastNotifier } from "../utils";

import { helix, cardio } from "ldrs";

import { AuthContext } from "../components/AuthProvider";

import profileImage from "../assets/Personal-settings-bro.png";
import statisticsImage from "../assets/Statistics-bro.png";
import { updateEmail, updatePassword, updateProfile } from "firebase/auth";

const Profile = () => {
  helix.register();
  cardio.register();

  const { currentUser, lessons, subjects, questions } =
    React.useContext(AuthContext);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<{ [field: string]: string }>({
    username: null,
    email: null,
    newPassword: null,
  });

  const [showPasswordInput, setShowPasswordInput] =
    React.useState<boolean>(false);

  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const [profileInformation, setProfileInformation] =
    React.useState<ProfileInformationType>({
      username: null,
      email: null,
      newPassword: null,
    });

  React.useEffect(() => {
    setProfileInformation({
      username: currentUser?.displayName,
      email: currentUser?.email,
    });
  }, [currentUser]);

  const togglePasswordInput = React.useCallback(() => {
    setShowPasswordInput((prev: boolean) => !prev);
  }, []);

  const togglePasswordVisibility = React.useCallback(() => {
    setShowPassword((prev: boolean) => !prev);
  }, []);

  const handleResetProfileInformation = React.useCallback(() => {
    setProfileInformation({
      username: currentUser?.displayName,
      email: currentUser?.email,
      newPassword: null,
    });

    setShowPassword(false);
    setShowPasswordInput(false);

    setErrors({
      username: null,
      email: null,
      newPassword: null,
    });
  }, [currentUser?.displayName, currentUser?.email]);

  const handleChange = React.useCallback((e, { name, value }) => {
    setProfileInformation((prev: ProfileInformationType) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleSaveProfile = React.useCallback(async () => {
    setLoading(true);

    let currentErrors = checkUpdateProfile(
      profileInformation?.username,
      profileInformation?.email,
      profileInformation?.newPassword
    );

    setErrors(currentErrors);

    if (
      _.isNil(currentErrors.username) &&
      _.isNil(currentErrors.email) &&
      _.isNil(currentErrors.newPassword)
    ) {
      if (
        !_.isEmpty(profileInformation?.username) &&
        currentUser?.displayName !== profileInformation?.username
      ) {
        await updateProfile(currentUser, {
          displayName: profileInformation?.username,
        });

        toastNotifier(
          ToastType.success,
          `Nom d'utilisateur mis à jour avec succès (lors de la prochaine connexion) : ${profileInformation?.username}`
        );
      }

      if (
        !_.isEmpty(profileInformation?.email) &&
        currentUser?.email !== profileInformation?.email
      ) {
        await updateEmail(currentUser, profileInformation?.email);

        toastNotifier(
          ToastType.success,
          `Email mis à jour avec succès : ${profileInformation?.email}`
        );
      }

      if (!_.isEmpty(profileInformation?.newPassword)) {
        await updatePassword(currentUser, profileInformation?.newPassword);

        toastNotifier(ToastType.success, `Mot de passe mis à jour avec succès`);
      }
    } else {
      if (currentErrors?.username) {
        classManager.addTemporaryClass("username", "horizontalShaking");
      }

      if (currentErrors?.email) {
        classManager.addTemporaryClass("email", "horizontalShaking");
      }

      if (currentErrors?.newPassword) {
        classManager.addTemporaryClass("newPassword", "horizontalShaking");
      }

      _.map(_.entries(currentErrors), ([field, message], index) => {
        if (message) {
          toastNotifier(ToastType.error, `${message}`);
        }
      });
    }

    setLoading(false);
  }, [currentUser, profileInformation]);

  return (
    <div className="background">
      <Grid
        columns={3}
        stackable
        style={{
          margin: "1em",
          height: "100%",
        }}
      >
        <Grid.Column width={6} verticalAlign="middle">
          <img
            alt="profile girl"
            src={profileImage}
            className="dropShadow fadeSlideIn"
            style={{
              objectFit: "contain",
              width: "100%",
              height: "100%",
            }}
          />
        </Grid.Column>
        <Grid.Column width={10}>
          <Grid.Row style={{ height: "48%" }}>
            <Grid.Column width={8}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <div
                  className="transparentBackground fadeSlideIn"
                  style={{
                    width: "45%",
                    padding: "3rem",
                    borderRadius: 10,
                  }}
                >
                  {(currentUser?.displayName !== profileInformation?.username ||
                    currentUser?.email !== profileInformation?.email ||
                    !_.isEmpty(profileInformation?.newPassword)) && (
                    <>
                      <div
                        className="fadeSlideIn zoomAnimation2 centeredChildren dropShadow"
                        style={{
                          position: "absolute",
                          top: -15,
                          left: -15,
                          width: "50px",
                          height: "50px",
                          cursor: "pointer",
                          borderRadius: "50%",
                          backgroundColor: Colors.cream,
                        }}
                      >
                        <Icon
                          name="redo"
                          size="big"
                          onClick={handleResetProfileInformation}
                          className="fadeSlideIn dropShadowInput"
                          style={{ color: Colors.danger }}
                        />
                      </div>

                      <div
                        className="fadeSlideInFromRight zoomAnimation2 centeredChildren dropShadow"
                        style={{
                          position: "absolute",
                          top: -15,
                          right: -15,
                          width: "50px",
                          height: "50px",
                          cursor: "pointer",
                          borderRadius: "50%",
                          backgroundColor: Colors.cream,
                        }}
                      >
                        <Icon
                          name="check"
                          size="big"
                          onClick={handleSaveProfile}
                          className="fadeSlideInFromRight dropShadowInput"
                          style={{ color: Colors.positive }}
                        />
                      </div>
                    </>
                  )}
                  {!loading ? (
                    <Form>
                      <Header
                        className="fadeSlideIn"
                        as="h2"
                        textAlign="center"
                        style={{
                          fontFamily: "websiteTitle",
                          paddingBottom: "1em",
                        }}
                      >
                        <Icon name="user" />
                        Informations du profil
                      </Header>
                      <Form.Field
                        error={errors?.username}
                        className="fadeSlideIn"
                      >
                        <label>Nom d'utilisateur</label>
                        <Input
                          id="username"
                          name="username"
                          icon="user"
                          placeholder="John Doe"
                          type="text"
                          onChange={handleChange}
                          value={profileInformation?.username}
                        />
                      </Form.Field>

                      <Form.Field error={errors?.email} className="fadeSlideIn">
                        <label>Email</label>
                        <Input
                          id="email"
                          name="email"
                          icon="mail"
                          placeholder="john.doe@gmail.com"
                          type="email"
                          onChange={handleChange}
                          value={profileInformation?.email}
                        />
                      </Form.Field>

                      {showPasswordInput ? (
                        <Form.Field
                          error={errors?.newPassword}
                          className="fadeSlideIn"
                        >
                          <label>Nouveau mot de passe</label>
                          <Input
                            id="newPassword"
                            name="newPassword"
                            icon={
                              <Icon
                                link={
                                  !_.isEmpty(profileInformation?.newPassword)
                                }
                                name={
                                  _.isEmpty(profileInformation?.newPassword)
                                    ? "lock"
                                    : showPassword
                                    ? "eye slash"
                                    : "eye"
                                }
                                color="black"
                                onClick={togglePasswordVisibility}
                              />
                            }
                            placeholder="***************"
                            type={showPassword ? "text" : "password"}
                            onChange={handleChange}
                            value={profileInformation?.newPassword}
                          />
                        </Form.Field>
                      ) : (
                        <div
                          onClick={togglePasswordInput}
                          className="modifyPassword fadeSlideIn"
                          style={{
                            cursor: "pointer",
                            marginTop: "2.5em",
                            textAlign: "center",
                          }}
                        >
                          <Icon name="lock" />
                          <span
                            style={{
                              textDecoration: "underline",
                              fontStyle: "italic",
                            }}
                          >
                            Modifier mon mot de passe
                          </span>
                        </div>
                      )}
                    </Form>
                  ) : (
                    <div
                      className="centeredChildren"
                      style={{ height: "100%", flexDirection: "column" }}
                    >
                      <div className="fadeSlideIn">
                        <l-cardio
                          size="100"
                          speed="1"
                          stroke="8"
                          color="white"
                        />
                      </div>
                      <p
                        className="fadeSlideIn"
                        style={{
                          fontFamily: "websiteTitle",
                          paddingTop: 25,
                          color: "white",
                          fontSize: "1.5em",
                        }}
                      >
                        Sauvegarde en cours ...
                      </p>
                    </div>
                  )}
                </div>
                <div
                  className="transparentBackground fadeSlideInFromRight"
                  style={{
                    width: "50%",
                    height: "100%",
                    padding: "2.6rem",
                    borderRadius: 10,
                  }}
                >
                  <Header
                    as="h2"
                    className="fadeSlideInFromRight"
                    textAlign="center"
                    style={{
                      fontFamily: "websiteTitle",
                      paddingBottom: "1em",
                    }}
                  >
                    <Icon name="chart bar" />
                    Statistiques
                  </Header>
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div style={{ width: "50%" }}>
                      <img
                        alt="statistics  on a computer"
                        src={statisticsImage}
                        className="dropShadow fadeSlideInFromRight"
                        style={{
                          objectFit: "contain",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>

                    <div
                      className="fadeSlideInFromRight"
                      style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "websiteTitle",
                          fontSize: "1.3em",
                        }}
                      >
                        <Icon
                          name="book"
                          size="big"
                          style={{ color: Colors.sageGreenDarker }}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <span
                          style={{
                            fontSize: "1.5em",
                            color: Colors.sageGreenDarker,
                          }}
                        >
                          {_.size(lessons)}
                        </span>{" "}
                        cours
                      </p>
                      <p
                        style={{
                          fontFamily: "websiteTitle",
                          fontSize: "1.3em",
                        }}
                      >
                        <Icon
                          name="folder"
                          size="big"
                          style={{ color: Colors.sageGreenDarker }}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <span
                          style={{
                            fontSize: "1.5em",
                            color: Colors.sageGreenDarker,
                          }}
                        >
                          {_.size(subjects)}
                        </span>{" "}
                        matières
                      </p>
                      <p
                        style={{
                          fontFamily: "websiteTitle",
                          fontSize: "1.3em",
                        }}
                      >
                        <Icon
                          name="question circle"
                          size="big"
                          style={{ color: Colors.sageGreenDarker }}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <span
                          style={{
                            fontSize: "1.5em",
                            color: Colors.sageGreenDarker,
                          }}
                        >
                          {_.size(questions)}
                        </span>{" "}
                        questions
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="centeredChildren" style={{ height: "50%" }}>
            <div
              className="centeredChildren fadeSlideInFromRight"
              style={{
                width: "97%",
                height: "95%",
                border: `dashed 5px ${Colors.ghostWhite}`,
                borderRadius: 20,
              }}
            >
              <span className="fadeSlideIn">
                <l-helix size="150" speed="2.5" color="white" />
              </span>
              <span
                className="fadeSlideInFromRight"
                style={{
                  fontFamily: "websiteTitle",
                  color: Colors.ghostWhite,
                  fontSize: "2em",
                }}
              >
                Fonctionnalité bientôt disponible ...
              </span>
            </div>
          </Grid.Row>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default Profile;
